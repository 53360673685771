import { PROOF_TYPE } from '../components';
import { store } from './';
import { VIDEO_FILE_EXTENTIONS } from './file-type.constants';
export const utilService = {
    getFileExtention,
    getFileName,
    getQueryStringValue,
    setStateAsync,
    getIsEditorDirty,
    searchTree,
    findParentsArray,
    findParentsArrayIds,
    findParentObjects,
    findChildrenArray,
    findChildrenArrayIds,
    updateTree,
    deleteNode,
    isProofTypeVideo,
    getUserInitial,
    getUserAnnotationPreferenceColor,
    getUserAvatarPreferenceColor,
    findPoint,
    timeout,
    isVideoProof,
    isHTMLLiveProof,
    getBrowserName,
    isDynamicLiveProof,
    isWebsiteProof,
    isProofReady,
    updateCDNURL,
    getOnlyHTMLPagePartFromCDNUrl,
    getOnlyLastPart,
    getDateOnlyISOString,
    parseDateToUTC,
    generate6DigitUUID
};

// function to find if given point
// lies inside a given rectangle or not.
// order : left, top, right, bottom
function findPoint(x1, y1, x2, y2, x, y) {
    if (x > x1 && x < x2 && y > y1 && y < y2) return true;

    return false;
}

function searchTree(tree, target, key) {
    if (tree[key] === target) {
        return tree;
    }
    for (const child of tree.children) {
        const res = searchTree(child, target, key);

        if (res) {
            return res;
        }
    }
}

// function isAncestor(node) {
//     var childrenIds = findChildrenArrayIds(node);

// }

function findParentsArrayIds(node, targetNode, keyName, nameProperty, childrenName) {
    var objectArray = {
        [childrenName]: node
    };
    var parentsArray = findParentsArray(
        objectArray,
        targetNode,
        keyName,
        nameProperty,
        childrenName
    );
    var ids = parentsArray.map((item) => item.id);
    return ids;
}

function findParentObjects(node, targetNode, keyName, nameProperty, childrenName) {
    var objectArray = {
        [childrenName]: node
    };
    var parentsArray = findParentsArray(
        objectArray,
        targetNode,
        keyName,
        nameProperty,
        childrenName
    );
    var ids = parentsArray.map((item) => {
        return { key: item.id, title: item.name };
    });
    return ids;
}

function findParentsArray(node, targetNode, keyName, nameProperty, childrenName) {
    // If current node name matches the search name, return
    // empty array which is the beginning of our parent result
    if (node[keyName] === targetNode) {
        return [];
    }

    // Otherwise, if this node has a tree field/value, recursively
    // process the nodes in this tree array
    if (Array.isArray(node[childrenName])) {
        for (var treeNode of node[childrenName]) {
            // Recursively process treeNode. If an array result is
            // returned, then add the treeNode.name to that result
            // and return recursively
            const childResult = findParentsArray(
                treeNode,
                targetNode,
                keyName,
                nameProperty,
                childrenName
            );

            if (Array.isArray(childResult)) {
                return [{ id: treeNode[keyName], name: treeNode[nameProperty] }].concat(
                    childResult
                );
            }
        }
    }
}

function findChildrenArrayIds(node) {
    var childrenArray = findChildrenArray(node);
    var ids = childrenArray.map((item) => item.id);
    return ids.filter((key) => key !== node.key);
}

function findChildrenArray({ key, title, children = [] }) {
    return children.reduce((r, o) => [...r, ...findChildrenArray(o)], [{ id: key, name: title }]);
}

function updateTree(
    tree,
    targetNodeProp,
    targetKeyValue,
    keyPropName,
    childrenPropName,
    updateValue
) {
    if (tree[keyPropName] === targetKeyValue) {
        tree[targetNodeProp] = updateValue;
    } else {
        tree[childrenPropName] = tree[childrenPropName].map(function (item) {
            return updateTree(
                item,
                targetNodeProp,
                targetKeyValue,
                keyPropName,
                childrenPropName,
                updateValue
            );
        });
    }
    return tree;
}

function deleteNode(
    tree,
    targetNodeProp,
    targetKeyValue,
    keyPropName,
    childrenPropName,
    updateValue
) {
    tree[childrenPropName] = tree[childrenPropName]
        .filter(function (item) {
            if (item[keyPropName] !== targetKeyValue) {
                return item;
            }
        })
        .map(function (item) {
            var newItem = { ...item };
            return deleteNode(
                newItem,
                targetNodeProp,
                targetKeyValue,
                keyPropName,
                childrenPropName,
                updateValue
            );
        });

    return tree;
}

function getFileExtention(fileName) {
    const lastDot = fileName.lastIndexOf('.');
    return fileName.substring(lastDot + 1);
}

function getFileName(fileName) {
    const lastDot = fileName.lastIndexOf('.');
    return fileName.substring(0, lastDot);
}

function getQueryStringValue(paramKey, searchTerm =  window.location.search, href =  window.location.href) {
    var paramsString = decodeURIComponent(searchTerm);
    var queryValue = '';
    if (!paramsString) {
        // if no params string, it may be a hash-based URL (".../#/...?mode=...")
        paramsString = decodeURI(href);
    }
    if (paramsString) {
        var splitQMark = paramsString.indexOf('?');
        if (splitQMark > -1) {
            paramsString = paramsString.substring(splitQMark, paramsString.length);
        }
    }
    paramKey = paramKey.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + paramKey + '=([^&#]*)');
    var results = regex.exec(paramsString);
    // var queryValue = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    var queryValue = results === null ? '' : results[1];
    // if (paramsString) {
    //     // look for "mode=" and the value that comes after
    //     var modeIndex = paramsString.lastIndexOf(paramKey);
    //     if (modeIndex > -1) {
    //         queryValue = paramsString.substring(
    //             modeIndex + paramKey.length,
    //             paramsString.length
    //         );
    //     }
    // }
    return queryValue;
}
/*
Usage:  await utilService.setStateAsync.bind(this)({
         documentId: s3TempInfoResponse.documentId,
});
*/
function setStateAsync(state) {
    return new Promise((resolve) => {
        this.setState(state, resolve);
    });
}
function getIsEditorDirty() {
    
    var haveAnnotationsChanges = false;
    
    var haveCommentsChanges = getIsAnnotationCommentsDirty();
    if (haveAnnotationsChanges || haveCommentsChanges) {
        return true;
    } else {
        // window.HTML5Demos._proofEditor = null;
        return false;
    }
}
function getIsAnnotationCommentsDirty() {
    const state = store.getState();
    const { alertStatuses } = state;
    var isDirty = alertStatuses && alertStatuses.isProofEditorDirty;
    return isDirty;
}

function isProofTypeVideo(fileName) {
    let ext = getFileExtention(fileName).replace('.', '');
    return VIDEO_FILE_EXTENTIONS.includes(ext);
}

function getUserInitial(name) {
    var initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
}

function getUserAnnotationPreferenceColor(preferences, userId){    
    
    var userPreference = preferences.find(x => x.userID == userId);

    if (userPreference !== undefined){
        return userPreference.color;
    }

    return '';
}

function getUserAvatarPreferenceColor(preferences, userId){    
    
    var userPreference = preferences.find(x => x.userID == userId);

    if (userPreference !== undefined){
        
        return  userPreference.avatarColor === undefined? userPreference.color : userPreference.avatarColor;
    }

    return '';
}

function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

function isVideoProof (version) {
    return version?.mediaType === 2 || version.mediaType === 4;
}
function isHTMLLiveProof (version) {
    return version?.mediaType === PROOF_TYPE.HTMLLive;
}

function isDynamicLiveProof (version) {
    return version?.mediaType === PROOF_TYPE.HTMLDynamicLive;
}

function isWebsiteProof (version) {
    return version?.mediaType === PROOF_TYPE.HTMLLive || version?.mediaType === PROOF_TYPE.HTMLDynamicLive;
}

function isProofReady(proofVersion) {
    let isProofReady = true;
    
    if (proofVersion.mediaType && proofVersion.status) {
        isProofReady = (
            proofVersion.mediaType === 2 ||
            proofVersion.mediaType === 4 ||
            proofVersion.mediaType === 5 ||
            proofVersion.mediaType === 6
        ) ? (
            proofVersion.status &&
            proofVersion.status.thumbnailServiceStatus === 2 &&
            proofVersion.status.metadataServiceStatus === 2 &&
            proofVersion.status.conversionServiceStatus === 2
        ) : proofVersion.status.documentProcessingStatus === 2;
    }

    return isProofReady;
}

function getBrowserName(browserInfo) {
    let browser;

    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
        browser = 'Opera';
    } else if (browserInfo.includes('Edg')) {
        browser = 'Edge';
    } else if (browserInfo.includes('Chrome')) {
        browser = 'Chrome';
    } else if (browserInfo.includes('Safari')) {
        browser = 'Safari';
    } else if (browserInfo.includes('Firefox')) {
        browser = 'Firefox';
    } else {
        browser = 'unknown';
    }

    return browser;
}

function updateCDNURL(url) {

    if(url) {
        url = url.replace(/\\/g, "/");
        url = url.substring(0, url.lastIndexOf("/") + 1);
        return url;
    }
}

function getOnlyHTMLPagePartFromCDNUrl(baseUrl, url) {

    if(baseUrl && url) {
        let lastPart = url.replace(baseUrl, '');
        console.log(lastPart);
        return lastPart;
    }
}

function getOnlyLastPart(url) {

    let lastPart = "";
    if(url) {
        lastPart = url.substring(url.lastIndexOf("/") + 1);
    }
    return lastPart;
}

function getDateOnlyISOString(dateValue) {
    var dateString =
        dateValue.getFullYear() +
        '-' +
        getMonth(dateValue) +
        '-' +
        getDate(dateValue) +
        'T00:00:00.00Z';
    return dateString;
}


function getMonth(date) {
    var month = date.getMonth() + 1;
    return month < 10 ? '0' + month : '' + month;
}

function getDate(date) {
    var day = date.getDate();
    return day < 10 ? '0' + day : '' + day;
}

function parseDateToUTC(isoDate) {
    return new Date(isoDate);
}


  
function generate6DigitUUID() {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
}
