//@ts-check
import React, { useState } from 'react';
import cx from 'classnames';
import { ShapeAction, drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { withVideoAnnotationConsumer } from '../Context/VideoAnnotationConsumer';
import { uuidv4 } from '../VideoAnnotationUtil';
import { videoAnnotationConstants } from '../Context/videoAnnotation.constants';
import { authorizationService } from '../../../../_helpers';
import { authorizationConstants } from '../../../_shared';
import ContributorsComponent from '../../ContributorsComponent';
import { modifyAnnotationColor } from '../../drawingHelper';
import { TextboxEditingTools } from '../../DocumentAnnotation/Subs/TextboxEditingTools';
import { AnnotationColorChangeTools } from '../../DocumentAnnotation/Subs/AnnotationColorChangeTools';
import { EditorToolboxZoom } from '../../DocumentAnnotation/Subs/EditorToolboxZoom';
import { documentAnnotationConstants } from '../../DocumentAnnotation/Context/documentAnnotation.constants';
import { convertZoomToScale } from '../../DocumentAnnotation/DocumentAnnotationUtil';
import { HighlightColorChangeTools } from '../../DocumentAnnotation/Subs/HighlightColorChangeTools';
import { RectangleIcon } from '../../../_shared/Icon/DesktopToolsIcons/RectangleIcon';
import { CircleIcon } from '../../../_shared/Icon/DesktopToolsIcons/CircleIcon';
import { TextboxIcon } from '../../../_shared/Icon/DesktopToolsIcons/TextboxIcon';
import { FreehandIcon } from '../../../_shared/Icon/DesktopToolsIcons/FreehandIcon';
import { ArrowIcon } from '../../../_shared/Icon/DesktopToolsIcons/ArrowIcon';
import { CommentIcon } from '../../../_shared/Icon/DesktopToolsIcons/CommentIcon';
import { PinIcon } from '../../../_shared/Icon/DesktopToolsIcons/PinIcon';
import { HighlightIcon } from '../../../_shared/Icon/DesktopToolsIcons/HighlightIcon';
import { StrikeThroughIcon } from '../../../_shared/Icon/DesktopToolsIcons/StrikeThroughIcon';


const VideoEditorToolbox = (props) => {
    const { ZOOM_STEP, ZOOM_SELECTIONS: zoomSelections } = documentAnnotationConstants;        
    const [currentTool, setCurrentTool] = useState('');
    const { ANNOTATION_TYPE } = drawingConstants;

    const onFreeHandStart = () => {
        props.setDisableArrow(true);
        const shapeType = ANNOTATION_TYPE.path.shape;
        setCurrentTool(shapeType);
        props.setRangeSelectorToCurrentFrame();
        props.toggleDrawingMode(true);
        const shape = {
            uuid: uuidv4(),
            uuid_parent: uuidv4(),
            type: shapeType,
            action: ShapeAction.START,
            onDrawCompleted: onShapeDrawComplete
        };

        props.mainDrawingCanvas.drawShape(shape);
        props.clearShapeTools();
    };
    const onDrawingToolClicked = (shapeObject) => {
        props.setIsPanningSelected(false);
        props.setDisableArrow(true);
        props.setSelectedTool(shapeObject.typeName);
        props.setInitDrawing(true);
        props.setIsComposite(true);
        // props.hoverCursor();
        if(shapeObject.typeName === highlight.typeName) {
             
             onHighlightPreDraw();              
        }
        else {
            props.clearShapeTools();
        }
    };
    const onDrawingStart = (shapeObject) => {        
        props.setCurrentAnnotationOnCanvas({});
        props.setDisableArrow(true);

        props.videoPlayer.pause();
        props.setIsCommentAddMode(false);
        props.setRangeSelectorToCurrentFrame();
        const shapeType = shapeObject.typeName;
        setCurrentTool(shapeType);
        const shape = {
            uuid: uuidv4(),
            uuid_parent: uuidv4(),
            type: shapeType,
            action: ShapeAction.START,
            onDrawCompleted: onShapeDrawComplete,
            // ...props.currentAnnotationOnCanvas
        };

       if(shapeObject.typeName === highlight.typeName) {
             
            onHighlightPreDraw();              
       }
       else {
           props.clearShapeTools();
       }

        modifyAnnotationColor(shape, props.userId, props.contributors);
        props.mainDrawingCanvas.drawShape(shape);
    };

    const onHighlightPreDraw = () => {
        props.setCurrentAnnotationOnCanvas({            
            type : highlight.typeName,
        }); 
        props.setShowShapeEditingTools(true);
    }
    const onCommentStampToolClicked = (shapeObject) => {
        props.setIsPanningSelected(false);        
        props.setDisableArrow(true);
        props.setSelectedTool(shapeObject.typeName);
        props.setInitDrawing(true);
        props.setIsComposite(true);
        props.startDrawing(
            shapeObject.typeName,
            props.selectedCanvas,
            onCommentStampDrawComplete,
            false
        );
        props.clearShapeTools();
    };
    const onShapeDrawComplete = (shape) => {
        console.log('shape draw complete.', shape);
        if (shape && shape.action === ShapeAction.ADD) {
            setCurrentTool('');
            props.setIsCommentAddMode(true);
            if(shape.type  === ANNOTATION_TYPE.commentStamp.typeName) {
                props.mainDrawingCanvas.selectObjectById(shape.uuid);
            }
            props.onCommentAdd(shape);
            props.toggleDrawingMode(false);
        }
    };

    const { rect, circle, textbox, path, arrow, commentStamp, pinPointer,highlight, strikeOut  } = ANNOTATION_TYPE;
    const onCommentStampDrawComplete = (shape) => {
        console.log(props.isComposite);
        if (props.selectedComment === -1) {
            console.log('shape draw complete.', shape);
            if (shape && shape.action === ShapeAction.ADD) {
                props.setIsCommentAddMode(true);
                props.mainDrawingCanvas.selectObjectById(shape.uuid);
                props.onCommentAdd(shape, null, props.selectedCanvas);
                props.toggleDrawingMode(false, props.selectedCanvas);
            }
            props.setSelectedTool('');
            props.setInitDrawing(false);
        }
        else {
            console.log('shape draw complete.', shape);
            if (shape && shape.action === ShapeAction.ADD) {
                props.onAnnotationMerge(shape, null,props.selectedCanvas);
                props.toggleDrawingMode(false, props.selectedCanvas);
            }
            props.setSelectedTool('');
            props.setInitDrawing(false); 
        }
    };

    const getItemClassnames = (shapeType) => {
        return cx({
            'mrnda-video-editor-area-toolbox-shapes--item': true,
            active: shapeType === props.selectedTool
        });
    };

    const onZoomInClicked = () => {
        let containerZoom = props.containerZoom + ZOOM_STEP;       
        props.setContainerScale(convertZoomToScale(containerZoom));
    };
    const onZoomOutClicked = () => {
        let containerZoom = props.containerZoom - ZOOM_STEP;
        if(containerZoom < 1) {
            return;
        }
        props.setContainerScale(convertZoomToScale(containerZoom));
    };

    const onFitWidthClicked = () => {
        
    };

    var isUserPermittedForAdd = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
    );

    return (
        <div
            style={props.compactNavbar === true?  { paddingTop: '0' , paddingBottom: '0', border: 'none' } : {}}
            className={`mrnda-video-editor-area-toolbox`}>

            <div
                className={`mrnda-video-editor-area-toolbox-contributors ${props.isCommentAddMode || props.proofVersion.isLocked || !isUserPermittedForAdd
                    ? ' authorization-disabled'
                    : ''
                    }`}
            >
                <ContributorsComponentHOC />
            </div>

            <div
                className={`mrnda-video-editor-area-toolbox-shapes`}
            >
                <div                    
                    style={{ display: 'flex', alignItems: 'center' }}
                    
                    className={cx({
                        'authorization-disabled' : props.proofVersion.isLocked || !isUserPermittedForAdd,
                        'skip-comment-click-outside' : true
                    })}
                    
                >
                    <div className={getItemClassnames(rect.typeName)} title={rect.name}>
                        {/* <i
                            onClick={() => onDrawingToolClicked(rect)}
                            className={rect.icon}></i> */}
                            <RectangleIcon onClick={() => onDrawingToolClicked(rect)} />
                    </div>
                    <div className={getItemClassnames(circle.typeName)} title={circle.name}>
                        {/* <i
                            onClick={() => onDrawingToolClicked(circle)}
                            className={circle.icon}></i> */}
                        <CircleIcon onClick={() => onDrawingToolClicked(circle)} />
                    </div>
                    <div
                        style={{}}
                        className={getItemClassnames(textbox.typeName)}
                        title={textbox.name}>
                        {/* <i
                            onClick={() => onDrawingToolClicked(textbox)}
                            className={textbox.icon}></i> */}
                        <TextboxIcon onClick={() => onDrawingToolClicked(textbox)} />
                    </div>
                    <div style={{}} className={getItemClassnames(path.typeName)} title={path.name}>
                        {/* <i
                            onClick={() => onDrawingToolClicked(path)}
                            className={path.icon}></i> */}
                        <FreehandIcon  onClick={() => onDrawingToolClicked(path)} />
                    </div>
                    <div style={{}} className={getItemClassnames(arrow.typeName)} title={arrow.name}>
                        {/* <i
                            onClick={() => onDrawingToolClicked(arrow)}
                            className={arrow.icon}></i> */}
                        <ArrowIcon onClick={() => onDrawingToolClicked(arrow)} />
                    </div>
                    <div style={{ marginTop: 2 }} className={getItemClassnames(commentStamp.typeName)} title={commentStamp.name}>
                        {/* <i
                            onClick={() => onCommentStampToolClicked(commentStamp)}
                            className={commentStamp.icon}></i> */}
                        <CommentIcon onClick={() => onCommentStampToolClicked(commentStamp)} />
                    </div>
                    

                    <div                        
                        className={getItemClassnames(pinPointer.typeName)}
                        title={pinPointer.name}
                        onClick={() => onDrawingToolClicked(pinPointer)} >
                        <PinIcon color={ pinPointer.typeName === props.selectedTool? 'var(--primary-color)' : 'var(--primary-text-color)' }  />
                    </div>
                    <div
                        style={{ marginTop: 2 }}
                        className={getItemClassnames(highlight.typeName)}
                        title={'Highlight'}
                        onClick={() => onDrawingToolClicked(highlight)}>
                        <HighlightIcon />
                    </div>
                    <div
                        style={{ marginTop: 2 }}
                        className={getItemClassnames(strikeOut.typeName)}
                        title={'Strikeout'}
                        onClick={() => onDrawingToolClicked(strikeOut)}>
                        <StrikeThroughIcon />
                    </div>
                </div>
                
                <div 
                    id='toolbox-shape-tools'
                    style={{display: 'flex'}}
                    className={
                    props.proofVersion.isLocked || !isUserPermittedForAdd
                        ? ' authorization-disabled'
                        : ''
                }>
                    {props?.currentAnnotationOnCanvas?.type === highlight.typeName ? <HighlightColorChangeToolsHOC /> :   <AnnotationColorChangeToolsHOC />}
                    
                </div>

            </div>

            <div id="toolbox-zoom-tools" className="mrnda-video-editor-area-toolbox-tools">

                <EditorToolboxZoom getItemClassnames={getItemClassnames}

                    setContainerZoom={props.setContainerZoom}
                    containerZoom={props.containerZoom}
                    onZoomInClicked={onZoomInClicked}
                    onZoomOutClicked={onZoomOutClicked}
                    onFitWidthClicked={props.fitToWidth}
                    fitToSpace={props.fitToHeight}
                    isPanningSelected= {props.isPanningSelected}
                    setIsPanningSelected ={props.setIsPanningSelected}
                    selectedCanvas={props.selectedCanvas}
                    setSelectedTool={props.setSelectedTool}
                    proofVersion={props.proofVersion}
                    onZoomRangeChanged={(selectedOption) => {
                        props.setContainerScale(convertZoomToScale(selectedOption.value));
                    }}
                />
            </div>
        </div>
    );
};
const ContributorsComponentHOC = withVideoAnnotationConsumer(ContributorsComponent);
const hoc = withVideoAnnotationConsumer(VideoEditorToolbox);
const AnnotationColorChangeToolsHOC = withVideoAnnotationConsumer(AnnotationColorChangeTools);
const HighlightColorChangeToolsHOC = withVideoAnnotationConsumer(HighlightColorChangeTools);

export { hoc as VideoEditorToolbox };
