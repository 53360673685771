// @ts-check
import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { calculateAspectRatioFit } from '../VideoAnnotation/VideoAnnotationUtil';
import { VideoPlayerControl } from '../VideoAnnotation/Subs/VideoPlayerControl';
const ProofVideoPlayer = ({
    proofVersion,
    resizeCounter,
    version,
    setVideoPlayer,
    setVideoCanvas,
    onVideoPlay,
    onVideoPause,
    seekToTime,
    timeMode,
    onTimeModeChange,
    onPlayerTimeUpdate,
    showCaption,
    currentCaption,
    canvasOpacity,
    playerColors,
    initialVolume,
    overrideTheme,
    playerId,
    compareClassName,
    isTranscriptionOnlyMode = false
}) => {
    const videoPlayer = useRef(null);
    const canvasContainer = useRef(null);
    const videoCanvas = useRef(null);
    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0);
    const [volume, setVolume] = useState(initialVolume || 0);
    const [isPaused, setIsPaused] = useState(true);
    const [transformContainer, setTransformContainer] = useState('');

    useEffect(() => {
        console.log('RESIZED', version);
        reScaleCanvasContainer();
        return () => {};
    }, [resizeCounter]);

    const reScaleCanvasContainer = () => {
        let video = videoPlayer.current;
        let containerHeight = canvasContainer.current.offsetHeight - 20;
        let containerWidth = canvasContainer.current.offsetWidth - 20;
        let ratio = calculateAspectRatioFit(
            video.videoWidth,
            video.videoHeight,
            containerWidth,
            containerHeight
        ).toFixed(2);
        let transformContainer = { transform: `scale(${ratio}, ${ratio})` };
        setTransformContainer(transformContainer);
    };

    const onLoadedVideoData = () => {
        videoPlayer.current.volume = volume;
        setVideoPlayer(videoPlayer.current);
        setVideoCanvas(videoCanvas.current);
    };

    const onLoadedMetadata = (event) => {
        reScaleCanvasContainer();
    };

    const onTimeUpdate = (event) => {
        // var currentTime = Math.round(this.player.currentTime());
        var currentTime = videoPlayer.current.currentTime;
        setCurrentTimeInSeconds(currentTime);
        onPlayerTimeUpdate && onPlayerTimeUpdate(currentTime);
    };

    const onVolumeChange = (value) => {
        videoPlayer.current.volume = value;
        setVolume(value);
    };

    const onPlayPause = () => {
        if (videoPlayer.current.paused) {
            // videoPlayer.current.play();
            onVideoPlay(videoPlayer.current);
            setIsPaused(false);
        } else {
            // videoPlayer.current.pause();
            onVideoPause(videoPlayer.current);
            setIsPaused(true);
        }
    };
    return (
        <>
            <div
                ref={canvasContainer}
                style={
                    {
                        // height: '100%',
                        // width: '100%',
                        // display: 'flex',
                        // justifyContent: 'center'
                    }
                }
                className="mrnda-video-canvas__player-area__container">
                <div
                    className="mrnda-video-canvas__player-area--canvas-wrapper"
                    style={{ ...transformContainer }}
                    // style={{ transform: 'scale(0.23, 0.23)' }}
                >
                    <canvas
                        ref={videoCanvas}
                        id="canvas"
                        className="auxiliary"
                        style={{
                            zIndex: 30,
                            position: 'absolute',
                            // width: '960px',
                            // height: videoTagHeight
                            opacity: canvasOpacity ? canvasOpacity : 1
                        }}
                        width={proofVersion?.fileMetadata?.width}
                        height={proofVersion?.fileMetadata?.height}
                    />

                    <div
                        style={
                            {
                                // width: 960,
                                // height: 'auto'
                            }
                        }
                        className="mrnda-video-editor-area-videoplayer-wrapper">
                        <video                            
                            id = {playerId}
                            ref={videoPlayer}
                            key={proofVersion?.fileMetadata?.fileURL}
                            crossOrigin="anonymous"
                            className={"mrnda-video-canvas__player-area__video-container--video " + compareClassName}
                            style={{
                                height: 'auto',
                                width: 'auto'
                                // maxWidth: '100%',
                                // maxHeight: '100%'
                            }}
                            preload="auto"
                            data-setup="{}"
                            onTimeUpdate={onTimeUpdate}
                            onLoadedMetadata={onLoadedMetadata}
                            onLoadedData={onLoadedVideoData}
                            onPlay={() => setIsPaused(false)}
                            onPause={() => setIsPaused(true)}
                            onEnded={() => setIsPaused(true)}>
                            <source src={proofVersion?.fileMetadata?.fileURL} type={'video/mp4'} />
                        </video>
                    </div>
                </div>
            </div>
            {showCaption && (
                <div className="mrnda-video-canvas__player-area__caption" title={currentCaption}>
                    {currentCaption && (
                        <span className={'mrnda-video-canvas__player-area__caption--text'}>
                            {currentCaption}
                        </span>
                    )}
                </div>
            )}
            <div className="mrnda-video-canvas__player-area__controls">
                {/* <VideoControl
                    fileMetadata={proofVersion?.fileMetadata}
                    volume={volume}
                    onVolumeChange={onVolumeChange}
                    isPaused={isPaused}
                    currentTimeInSeconds={currentTimeInSeconds}
                    onPlayPause={onPlayPause}
                    onProgressClick={onProgressClick}
                /> */}
                <VideoPlayerControl
                    isPaused={isPaused}
                    onVolumeChange={onVolumeChange}
                    volume={volume}
                    videoMetadata={proofVersion?.fileMetadata}
                    onPlayPauseToggle={onPlayPause}
                    currentTimeInSeconds={currentTimeInSeconds}
                    seekToTime={seekToTime}
                    timeMode={timeMode}
                    onTimeModeChange={onTimeModeChange}
                    showAnnotationMarkers={false}
                    playerColors={playerColors}
                    isTranscriptionOnlyMode={isTranscriptionOnlyMode}                    
                />
            </div>
        </>
    );
};
const nogo = () => {};
export { ProofVideoPlayer };
