import React from 'react';

export const HighlightIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '21px',
                width: '21px',
                ...mergedStyle
            }}
            {...restProps}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.928418"
                    y="14.6633"
                    width="13.9399"
                    height="1.52833"
                    rx="0.764164"
                    fill="currentColor"
                    stroke="white"
                    strokeWidth="0.4"
                />
                <path
                    d="M4.22659 11.0664L1.81152 13.4814L4.28696 14.0248L5.736 12.5758"
                    stroke="currentColor"
                    strokeWidth="0.853854"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="3.32081"
                    y="10.1611"
                    width="2.56156"
                    height="4.26927"
                    rx="0.426927"
                    transform="rotate(-45 3.32081 10.1611)"
                    stroke="currentColor"
                    strokeWidth="0.853854"
                />
                <path
                    d="M5.5174 8.73395C4.99352 8.21006 5.02208 7.35229 5.57965 6.86441L10.7318 2.3563C11.2392 1.91228 12.004 1.93774 12.4808 2.41453L14.0855 4.01921C14.5623 4.496 14.5878 5.26081 14.1438 5.76826L9.63563 10.9204C9.14776 11.478 8.28998 11.5065 7.7661 10.9826L5.5174 8.73395Z"
                    stroke="currentColor"
                    strokeWidth="0.853854"
                />
            </svg>
        </div>
    );
};
