import React from 'react';

export const StrikeThroughIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '21px',
                width: '21px',
                ...mergedStyle
            }}
            {...restProps}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.4375" width="9" height="1.125" rx="0.5625" fill="currentColor" />
                <rect
                    x="3.3125"
                    y="9.4375"
                    width="3.375"
                    height="1.125"
                    rx="0.5625"
                    fill="currentColor"
                />
                <path
                    d="M5.5625 1.5625L5.5625 10C5.5625 10.3107 5.31066 10.5625 5 10.5625C4.68934 10.5625 4.4375 10.3107 4.4375 10L4.4375 1.5625L5.5625 1.5625Z"
                    fill="currentColor"
                />
                <path
                    d="M8.9375 0.4375C9.24816 0.4375 9.5 0.68934 9.5 1L9.5 2.125C9.5 2.43566 9.24816 2.6875 8.9375 2.6875C8.62684 2.6875 8.375 2.43566 8.375 2.125L8.375 1C8.375 0.68934 8.62684 0.4375 8.9375 0.4375Z"
                    fill="currentColor"
                />
                <path
                    d="M1.0625 0.4375C1.37316 0.4375 1.625 0.68934 1.625 1L1.625 2.125C1.625 2.43566 1.37316 2.6875 1.0625 2.6875C0.75184 2.6875 0.5 2.43566 0.5 2.125L0.5 1C0.5 0.68934 0.75184 0.4375 1.0625 0.4375Z"
                    fill="currentColor"
                />
                <rect
                    x="0.275"
                    y="4.7125"
                    width="9.45"
                    height="1.575"
                    rx="0.7875"
                    fill="currentColor"
                    stroke="white"
                    strokeWidth="0.45"
                />
            </svg>
        </div>
    );
};
