import React, { useEffect, useState } from 'react';
import { MultiSelect } from '../../_shared';
import { workflowService } from '../../Settings/CustomStatus/services/workflow.service';
import { proofService } from '../proof.service';
import PulseLoader from '../../_shared/Loaders/PulseLoader';
import { useLiveUpdate } from '../../App/LiveUpdate/LiveUpdateContext';
import useStateRef from 'react-usestateref';
import { store, utilService } from '../../../_helpers';
import { alertActions } from '../../App';
import { Modal, Button, Spinner } from 'react-bootstrap';
import CustomDatePicker from '../../_shared/CustomDateTimePicker/CustomDateTimePicker';
import { isBefore } from 'date-fns';

const ProofVersionStatus = ({ proofVersion, updateProofVersion }) => {
    const [workflows, setWorkflows] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow, selectedWorkflowRef] = useStateRef();
    const [workflowStatuses, setWorkflowStatuses] = useState([]);
    const [selectedStatusId, setSelectedStatusId] = useState(proofVersion.workflowStatusId);
    const [isLoading, setIsLoading] = useState(false);
    const [showActionButtons, setShowActionButtons] = useState(false);
    const [currentWFState, setCurrentWFState] = useState({
        workflowId: proofVersion?.workflowId,
        workflowStatusId: proofVersion?.workflowStatusId,
        workflowDueDate: proofVersion?.workflowDueDate
    });

    const [duedateValue, setDuedateValue] = useState(null);

    const { setWorkflowListeners } = useLiveUpdate();

    useEffect(() => {
        if (proofVersion.id === undefined) {
            return;
        }

        setSelectedWorkflow(undefined);
        setSelectedStatusId(proofVersion.workflowStatusId);
        setCurrentWFState({
            workflowId: proofVersion?.workflowId,
            workflowStatusId: proofVersion?.workflowStatusId,
            workflowDueDate: proofVersion?.workflowDueDate
        });

        getWorkflowData(true);
        setLiveUpdateHandler();
    }, [proofVersion.id]);

    useEffect(() => {
        if (!selectedWorkflow) {
            return;
        }

        updateStatuses(selectedWorkflow);
    }, [selectedWorkflow]);

    useEffect(() => {
        if (proofVersion.workflowDueDate && isValidDate(proofVersion.workflowDueDate)) {
            const parsedDate = new Date(proofVersion.workflowDueDate.slice(0, -1));

            if (!isNaN(parsedDate.getTime())) {
                setDuedateValue(parsedDate);
                setCurrentWFState({
                    ...currentWFState,
                    workflowDueDate: parsedDate
                });
            }
        }
    }, []);

    const isValidDate = (dateObject) =>
        dateObject && new Date(dateObject).toString() !== 'Invalid Date';

    const setLiveUpdateHandler = () => {
        setWorkflowListeners(handleWorkflowUpdate, (tenantId) => {
            return {
                key: 'Editor',
                value: `${tenantId}/WorkflowUpdated`
            };
        });
    };

    const getWorkflowData = async (shouldSetSelectedWorkflow) => {
        setIsLoading(true);
        var { workflows } = await workflowService.getAll(false, true);

        var rowData = [];
        workflows.forEach((element) => {
            var rowItem = {
                label: element.name,
                value: element.id,
                ...element
            };

            rowData.push(rowItem);

            if (shouldSetSelectedWorkflow === true) {
                if (element.id === proofVersion.workflowId) {
                    setSelectedWorkflow(rowItem);
                }
            }
        });

        setWorkflows(rowData);
        setIsLoading(false);
        return rowData;
    };

    const updateStatuses = (selected) => {
        var rowData = [];
        selected.statuses.forEach((element) => {
            var rowItem = {
                label: element.name,
                value: element.id,
                ...element
            };

            rowData.push(rowItem);
        });

        setWorkflowStatuses(rowData);
    };

    const handleWorkflowUpdate = async (data) => {
        setIsLoading(true);
        var updatedWorkflows = await getWorkflowData(false);

        if (selectedWorkflowRef.current) {
            updateStatuses(updatedWorkflows.find((x) => x.id === selectedWorkflowRef.current.id));
        }

        setIsLoading(false);
    };

    const onWorkflowChange = (selectedOptions) => {
        setShowActionButtons(true);
        setSelectedWorkflow(selectedOptions);
        setSelectedStatusId(undefined);
        setDuedateValue(undefined);
    };

    const handleWorkflowStatusChanged = async (selectedOptions) => {
        if (
            selectedOptions.id === proofVersion.workflowStatusId &&
            selectedWorkflow.id === proofVersion.workflowId
        ) {
            setSelectedStatusId(selectedOptions.id);
            return;
        }

        setSelectedStatusId(selectedOptions.id);
        setDuedateValue(null);
        setShowActionButtons(true);

        // setIsLoading(true);

        // var request = {
        //     id: proofVersion.id,
        //     proofId: proofVersion.proofId,
        //     workflowId: selectedWorkflow.id,
        //     workflowStatusId: selectedOptions.id
        // };

        // await proofService.updateProofVersionWorkflow(request);

        // let updated = {...proofVersion};

        // updated.workflowId = request.workflowId;
        // updated.workflowStatusId = request.workflowStatusId;

        // updateProofVersion(updated);

        // setIsLoading(false);

        // store.dispatch(alertActions.success('Workflow status updated'));
    };

    const handleWorkflowChanged = async () => {
        if (!selectedStatusId) {
            store.dispatch(alertActions.error('Please select a status'));
            return;
        }

        setIsLoading(true);

        var request = {
            id: proofVersion.id,
            proofId: proofVersion.proofId,
            workflowId: selectedWorkflow.id,
            workflowStatusId: selectedStatusId,
            ...(duedateValue && {
                workflowDueDate: utilService.getDateOnlyISOString(duedateValue)
            })
        };

        await proofService.updateProofVersionWorkflow(request);

        let updated = { ...proofVersion };

        updated.workflowId = request.workflowId;
        updated.workflowStatusId = request.workflowStatusId;
        updated.workflowDueDate = request.workflowDueDate;

        setCurrentWFState({
            workflowId: request?.workflowId,
            workflowStatusId: request?.workflowStatusId,
            workflowDueDate: request?.workflowDueDate
        });

        updateProofVersion(updated);
        setIsLoading(false);
        setShowActionButtons(false);
        store.dispatch(alertActions.success('Workflow status updated'));
    };

    return (
        <>
            {workflows && workflows.length > 0 && (
                <div className="proof-version-workflow-wrapper-container">
                    <div className="proof-version-workflow-container">
                        <WorkflowSelector
                            {...{
                                title: 'Select Workflow',
                                options: workflows,
                                selectedItem:
                                    workflows[
                                        workflows.findIndex((x) => x.id == selectedWorkflow?.id)
                                    ],
                                onSelectionChange: (selectedOptions) =>
                                    onWorkflowChange(selectedOptions),
                                isLoading
                            }}
                        />

                        <WorkflowSelector
                            {...{
                                title: 'Select Status',
                                options: workflowStatuses,
                                selectedItem:
                                    workflowStatuses[
                                        workflowStatuses.findIndex((x) => x.id == selectedStatusId)
                                    ],
                                onSelectionChange: handleWorkflowStatusChanged,
                                isLoading
                            }}
                        />
                    </div>

                    <div className="proof-version-workflow-datepicker-container">
                        <span>Due Date</span>
                        <div
                            style={{
                                width: '47%'
                            }}>
                            <CustomDatePicker
                                value={duedateValue}
                                isDateOnly={true}
                                isClearable={true}
                                minDate={new Date()}
                                dateFormat={'yyyy/MM/dd'}
                                onChange={(date) => {
                                    setShowActionButtons(true);
                                    setDuedateValue(date);
                                }}
                                inputWidth={'inherit'}
                                inputFontSize={'12px'}
                            />
                        </div>
                    </div>

                    {showActionButtons && (
                        <div className="proof-version-workflow-btn-container">
                            <Button
                                variant=""
                                disabled={false}
                                className="mrnda-btn btn-sm"
                                onClick={handleWorkflowChanged}>
                                Save
                            </Button>
                            <Button
                                className="mrnda-btn--secondary btn-sm"
                                onClick={() => {
                                    setShowActionButtons(false);
                                    var selected = workflows.find(
                                        (x) => x.id === currentWFState?.workflowId
                                    );
                                    setSelectedWorkflow(selected);
                                    setSelectedStatusId(currentWFState?.workflowStatusId);
                                    var parsedDate = currentWFState?.workflowDueDate
                                        ? new Date(currentWFState?.workflowDueDate)
                                        : null;
                                    setDuedateValue(parsedDate);
                                }}>
                                Cancel
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

const WorkflowSelector = ({
    title,
    options,
    selectedItem,
    onSelectionChange,
    isLoading = false
}) => {
    return (
        <div className="proof-version-workflow-selection">
            <span>{title}</span>
            <div className="workflow-selection-host">
                <div className="workflow-selection-control">
                    <MultiSelect
                        className="hello"
                        style={{ width: '100%' }}
                        options={options}
                        selectedOptions={[selectedItem]}
                        onChange={onSelectionChange}
                        closeMenuOnSelect={true}
                    />
                </div>
                {isLoading === true && (
                    <div className="workflow-selection-loader">
                        <PulseLoader size="20px" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProofVersionStatus;
