import React from 'react';

const CrossIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '26px',
                width: '26px',
                ...mergedStyle
            }}
            {...restProps}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                {/* <rect width="20" height="20" fill="black" fillOpacity="0.01" /> */}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.6464 4.64645C14.8417 4.45118 15.1583 4.45118 15.3536 4.64645C15.5488 4.84171 15.5488 5.15829 15.3536 5.35355L10.7071 10L15.3536 14.6464C15.5488 14.8417 15.5488 15.1583 15.3536 15.3536C15.1583 15.5488 14.8417 15.5488 14.6464 15.3536L10 10.7071L5.35355 15.3536C5.15829 15.5488 4.84171 15.5488 4.64645 15.3536C4.45118 15.1583 4.45118 14.8417 4.64645 14.6464L9.29289 10L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L10 9.29289L14.6464 4.64645Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default CrossIcon;
