import React from 'react';

export const ArrowIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '21px',
                width: '21px',
                ...mergedStyle
            }}
            {...restProps}
            >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="13.5208"
                    y="5.30273"
                    width="8"
                    height="1.10611"
                    rx="0.553055"
                    transform="rotate(90 13.5208 5.30273)"
                    fill="currentColor"
                />
                <rect
                    x="13.5208"
                    y="13.3027"
                    width="8"
                    height="1.10611"
                    rx="0.553055"
                    transform="rotate(-180 13.5208 13.3027)"
                    fill="currentColor"
                />
                <path
                    d="M12.5208 13.3027L0.891006 1.67299C0.675024 1.45701 0.675024 1.10683 0.891006 0.890849C1.10699 0.674868 1.45716 0.674869 1.67314 0.890849L13.3029 12.5206L12.5208 13.3027Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};
