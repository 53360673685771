import React from 'react';

const SideCollapseIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '22px',
                width: '22px',
                ...mergedStyle
            }}
            {...restProps}
            >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 1H6.5V15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1ZM0 2C0 0.895431 0.895431 0 2 0H6.5H6.66663H7.5H7.66663H18C19.1045 0 20 0.895431 20 2V14C20 15.1046 19.1045 16 18 16H7.66663H7.5H6.66663H6.5H2C0.89543 16 0 15.1046 0 14V2ZM7.66663 1H18C18.5522 1 19 1.44771 19 2V14C19 14.5523 18.5522 15 18 15H7.66663V1Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default SideCollapseIcon;
