import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { ShapeAction, drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { VideoAnnotationContext } from './VideoAnnotationContext';
import {
    getVideoCurrentTimers,
    getVideoTimers,
    calculateAspectRatioFit,
    uuidv4
} from '../VideoAnnotationUtil';
import {
    videoAnnotationConstants,
    sidebarTabViewMode,
    transcriptionStatus
} from './videoAnnotation.constants';
import { fabric } from 'fabric';
import { videoCommentService } from '../videoComment.service';
import { authorizationService, contributorsHelper } from '../../../../_helpers';
import { authorizationConstants } from '../../../_shared';
import { useLiveUpdate } from '../../../App/LiveUpdate/LiveUpdateContext';
import { videoTranscriptionService } from '../../VideoTranscription';
import { modifyAnnotationColor, additionalProps, drawSelectionHighlightEffect, clearSelectionEffect } from '../../drawingHelper';
import { calculateVideoAnnotationCenterPoint }  from '../../../../_helpers/annotationArrowHelper';
import { volumeHelper }  from '../../../../_helpers/volumeHelper';
import useStateRef from 'react-usestateref';
import { usePanning } from '../../../../_helpers/hooks/usePanning';
import { createMarkers } from '../videoAnnotationHelper';
import { tenantService, userService } from '../../..';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import { useSelectionHighlightEffect } from '../../useSelectionHighlightEffect';
import { GlobalContext } from '../../../../AppWrapper';


const DRAW_ARROW_DELAY = 300;
const videoMetadataInit = {
    proofId: '',
    proofVersionId: '',
    currentUser: {},
    currentTenant: {},
    s3Config: {},
    poster: '',
    fileURL: null,
    mimeType: 'video/mp4',
    frameRate: 0,
    frameCount: 0,
    durationInSeconds: 0,
    durationInTimeCode: '00:00.00',
    durationInText: '00:00:00',
    videoWidth: 0,
    videoHeight: 0,
    videoTagWidth: 0,
    videoTagHeight: 0
};

const transcriptionsInit = {
    status: transcriptionStatus.LOADING,
    transcribeData: []
};

const { ANNOTATION_TYPE } = drawingConstants;

    const {
        rect,
        circle,
        textbox,
        path,
        arrow,
        commentStamp,
        pinPointer,
        highlight,
        strikeOut,
        textHighlight
    } = ANNOTATION_TYPE;

function VideoAnnotationProvider({ children }) {
    const [proofVersion, setProofVersion] = useState({});

    const [mainDrawingCanvas, setMainDrawingCanvas] = useState(null);
    const [videoPlayer, setVideoPlayer] = useState(null);

    const [videoMetadata, setVideoMetadata] = useState(videoMetadataInit);

    const [timeMode, setTimeMode] = useState(videoAnnotationConstants.TIME_MODE.TIME_CODE);

    const [annotationComments, setAnnotationComments] = useState([]);
    const [selectedAnnotation, setSelectedAnnotation] = useState([]);

    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0);
    const [currentFrameNumber, setCurrentFrameNumber] = useState(0);
    const [currentTimeInText, setCurrentTimeInText] = useState('00:00:00');
    const [currentTimeInTimeCode, setCurrentTimeInTimeCode] = useState('00:00.00');

    const [isCommentAddMode, setIsCommentAddMode] = useState(false);
    const [canvasTextEditEntered, setCanvasTextEditEntered] = useState(false);
    const [rangeSelectorValue, setRangeSelectorValue] = useState([0, 0]);

    const [transcriptions, setTranscriptions] = useState(transcriptionsInit);

    const [sidebarTabView, setSidebarTabView] = useState(sidebarTabViewMode.COMMENT);
    const [currentCaption, setCurrentCaption] = useState('');
    const [currentTool, setCurrentTool] = useState('');
    const [videoPlayerContainerTransform, setVideoPlayerContainerTransform] = useState({});
    const [userAnnotationColor, setUserAnnotationColor] = useState('#ff0000');
    const [userId, setUserId] = useState('');
    const [userFullName, setUserFullName] = useState(null);
    const [contributors, setContributors, contributorsRef] = useStateRef(null);
    const [selectedComment, setSelectedComment] = useState(-1);
    const [arrowPoint1, setArrowPoint1] = useState([{x: 0, y:0} ]);
    const [arrowPoint2, setArrowPoint2] = useState([{x: 0, y:0} ]);
    const [disableArrow, setDisableArrow] = useState(false);
    const [containerScale, setContainerScale] = useState();
    const [arrowColor, setArrowColor] = useState();
    const [isProofCompare, setIsProofCompare] = useState(false);
    const [currentUuid, setCurrentUuid] = useState('');
    const [currentAnnotationOnCanvas, setCurrentAnnotationOnCanvas, currentAnnotationOnCanvasRef] = useStateRef(undefined);
    const [showShapeEditingTools, setShowShapeEditingTools] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [editAllowedComments, setEditAllowedComments] = useState([]);
    const [editAllowedReplies, setEditAllowedReplies] = useState([]);
    const [selectedTool, setSelectedTool] = useState('');
    const [initDrawing, setInitDrawing] = useState(false);
    const [arrowStartPoints, setArrowStartPoints ] = useState([]);
    const [containerZoom, setContainerZoom] = useState(undefined);
    const [isComposite, setIsComposite] = useState(false);
    const [isCommentMerge, setIsCommentMerge] = useState(false);
    const [isPanningSelected, setIsPanningSelected] = useState(false);
    const [proofSettings, setProofSettings] = useState({});
    const [hotKeyEnabled, setHotKeyEnabled] = useState(true);
    const {
        connectionStatus,
        changeTarget,
        setEditorCommentListeners,
        setEditorAttachmentListeners,
        setCommentDeleteListener,
        setCommentReplyListener,
        setCommentStatusListener,
        setCommentAnnotationUpdateListener,
        setTranscriptionListeners,
        setCommentUpdateListener,
        setCommentStatusListeners
    } = useLiveUpdate();
    const donotHanldeLiveUpdate = useRef(false);
    const [hasNewComment, setHasNewComment] = useState(false);
    const [newCommentCount, setNewCommentCount] = useState(0);
    const newComments = useRef([]);
    
    const [commentsDeleted, setCommentsDeleted] = useState(false);
    const [deletedCommentCount, setDeletedCommentCount] = useState(0);
    const deletedComments = useRef([]);

    const annotationCommentsRef =  useRef([]); 
    const currentFrameNumberRef =  useRef([]); 
    const mainDrawingCanvasRef = useRef([]); 
    const proofVersionRef = useRef([]); 
    const commentArrowPointFindersRef = useRef(new Map());
    const [volume, setVolume] = useState(0);
    const videoPlayerRef = useRef(null);
    const editorArea = useRef(null);
    const [editingCommentId, setEditingCommentId] = useState('');
    const [markers, setMarkers, markersRef] = useStateRef([]);    
    const [commentStatuses, setCommentStatues] = useState([]);
    const [selectedToolbarAction, setSelectedToolbarAction] = useState('None');
    const [canvasLimitExceeded, setCanvasLimitExceeded] = useState(false);
    const [hasExtraTopBar, setHasExtraTopBar] = useState(true);
    

    const { user, updateUserPreferences } = useContext(GlobalContext);
    const userPreferences = user?.authData?.userPreference || null;

    const some = usePanning(videoPlayerRef.current, editorArea.current, isPanningSelected);
    const { isMobile } = useScreenSizes();
    useSelectionHighlightEffect(isMobile, disableArrow, mainDrawingCanvas, annotationComments[selectedComment]);

    const setPlayerArea = (player) =>{        
        videoPlayerRef.current = player;
    }
    const setEditorArea = (editor) =>{
        editorArea.current = editor;
    }
    useEffect(()=>{

        if(!containerScale) { return }

        setContainerZoom(containerScale * 100);
        let transformContainer = { transform: `scale(${containerScale}, ${containerScale})` };
        setVideoPlayerContainerTransform(transformContainer);

        
        redrawArrow(containerScale * 100)

    }, [containerScale])

    

    useEffect(() => 
    {
        annotationCommentsRef.current  = annotationComments;
    }, [annotationComments]);

    useEffect(() => 
    {
        proofVersionRef.current  = proofVersion;
    }, [proofVersion]);

    useEffect(() => 
    {
        mainDrawingCanvasRef.current  = mainDrawingCanvas;
    }, [mainDrawingCanvas]);

    useEffect(() => {
        var timers = getVideoCurrentTimers(currentTimeInSeconds, videoMetadata.frameRate);
        setCurrentFrameNumber(timers.currentFrameNumber);
        currentFrameNumberRef.current = timers.currentFrameNumber;
        setCurrentTimeInText(timers.currentTimeInText);
        setCurrentTimeInTimeCode(timers.currentTimeInTimeCode);
    }, [currentTimeInSeconds]);

    useEffect(() => {
        let timers = getVideoTimers(videoMetadata.durationInSeconds, videoMetadata.frameRate);
        let changedVideoMetadata = {
            ...videoMetadata,
            ...timers
        };

        setVideoMetadata(changedVideoMetadata);
    }, [videoMetadata.durationInSeconds, videoMetadata.frameRate]);
    useEffect(() => {
        if(selectedComment !== -1 && mainDrawingCanvas){            
            selectAnnotationOnCanvas();
            calculateCommentArrowPoint(selectedComment);
            var comment = annotationComments[selectedComment];
            var preferredColor = contributorsHelper.findCommentColor(comment.annotations[0]);            
            setArrowColor(preferredColor);
            setDisableArrow(false);
            handleUpdateUserPreferences({showCommentBar: true}, false);
        }
        else if (selectedComment === -1 && mainDrawingCanvas){
            setDisableArrow(true);
            mainDrawingCanvas.clearSelection();
            onSelectedAnnotationCleared();
        }
        
    }, [selectedComment]);

    useState( ()=> {
            
        volumeHelper.get().then((volume) => {
            console.log('videoVolume :' + volume );
        setVolume(volume);
        });
        
    }, [])

    useEffect(() => {
       if(isCommentAddMode){
            handleUpdateUserPreferences({showCommentBar: true}, false);
        }
    }, [isCommentAddMode]);


    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [userPreferences?.showCommentBar, userPreferences?.expandedTimeline]);

    const changeLiveUpdateTarget = (proofId, proofVersionId, tenantId)=>{             
        const targets = [
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentAdded`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentDeleted`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentReply`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentReplyDeleted`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentAttachmentDeleted`
            },
           
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentStatus`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentAnnotationUpdate`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/TranscriptionCompleted`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/TranscriptionStarted`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentUpdate`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentReplyUpdated`
            },            
            {
                key: 'Editor',
                value: `${tenantId}/CommentStatusListUpdated`
            },            
            {
                key: 'Editor',
                value: `${tenantId}/WorkflowUpdated`
            },                      
        ];
        changeTarget(targets);
        setEditorCommentListeners(handleNewCommentAdded, handleCommentReplyDeleted,handleCommentReplyUpdated, targets);
        setEditorAttachmentListeners(handleCommentAttachmentDeleted);
        setCommentDeleteListener(handleCommentDeleted, targets);
        setCommentReplyListener(handleCommentReply, targets);
        setCommentStatusListener(handleStatusReply, targets)
        setCommentAnnotationUpdateListener(handleCommentAnnotationUpdate, targets)
        setTranscriptionListeners(handleTranscriptionCompleted, handleTranscriptionStarted, targets)
        setCommentUpdateListener(handleCommentUpdate, targets);
        setCommentStatusListeners(handleCommentStatusListUpdate, targets)
    }

    const handleCommentStatusListUpdate = async (data) => {
        var { statuses } = await tenantService.getAllCommentStatus(false);            
        setCommentStatues(statuses);  
        var payLoad = {
            proofId: proofVersionRef.current.proofId,
            proofVersionId: proofVersionRef.current.id
        };
        var videoCommentsResponse = await videoCommentService.getVideoComments(payLoad);
        setAnnotationComments(videoCommentsResponse); 
    }

    const handleCommentReplyUpdated = (data) => {        
        var { commentId, updatedReply } = data;
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedComment = { ...comment };
            if (modifiedComment.id == commentId) {
                var index = modifiedComment.replies.findIndex((x) => x.id === updatedReply.id);
                modifiedComment.replies[index] = updatedReply;
            }
            return modifiedComment;
        });
        setAnnotationComments(modified);
    };

    const handleCommentUpdate = (data) => {
        console.log(selectedAnnotation);
        var { commentId, updatedComment } = data;
        let editedAnnotationComment;
        var modified = annotationCommentsRef.current.map((comment) => {
            if(comment.id === commentId) {
                comment = updatedComment;
            }
            return comment;
        });
        setAnnotationComments(modified);        
    };

    const handleTranscriptionStarted = (data) =>{
        setTranscriptions((prevState) => {
            return { ...prevState, status: transcriptionStatus.IN_PROGRESS };
        });
    }
    const handleTranscriptionCompleted = async (data) => {    
       
        var { proofId, proofVersionId } = data;
        var transcriptions = await videoTranscriptionService.getTranscribeText(
            proofId,
            proofVersionId
        );
        setTranscriptions(transcriptions);

    };

    const handleCommentAttachmentDeleted = (data) => {
        var { commentId, attachment } = data;

        var isReply = attachment.replyId !== null ? true : false;

        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedComment = { ...comment };
            if (modifiedComment.id == commentId) {

                if (isReply) {

                    let reply = modifiedComment.replies.find((x) => x.id === attachment.replyId);
                    let index = reply.attachments?.findIndex((x) => x.id === attachment.id);
                    reply.attachments.splice(index, 1);

                } else {
                    let index = modifiedComment.attachments?.findIndex((x) => x.id === attachment.id);
                    modifiedComment.attachments.splice(index, 1);
                }

            }
            return modifiedComment;
        });
        setAnnotationComments(modified);
    };
    const handleCommentAnnotationUpdate = (data) => {
        console.log(selectedAnnotation);
        var { commentId, annotations } = data;

        let currentFrameModfied = false;
        let editedannotationComment;
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedcomment = { ...comment };
            if (modifiedcomment.id == commentId) {
                modifiedcomment.annotations = annotations;

                if (modifiedcomment.frameRange[0] === currentFrameNumberRef.current) {
                    currentFrameModfied = true;
                }
                editedannotationComment = modifiedcomment;
            }

            return modifiedcomment;
        });

        setAnnotationComments(modified);

        if (currentFrameModfied) {


            //Get other annotations in this frame 
            var otherAnnotations = annotationCommentsRef.current.filter(x=> x.frameRange[0] === currentFrameNumberRef.current && x.id !== editedannotationComment.id)
            redraw(modified, getIsSelectable(), currentFrameNumberRef.current);
        }
    };
    const getIsSelectable = () => {
        let isUserPermittedToAdd = authorizationService.isUserPermitted(
            authorizationConstants.ProofEditor.KEY,
            authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
        );

        let isSelectable = proofVersion.isLocked || !isUserPermittedToAdd ? false : true;
        return isSelectable;
    }
    const handleStatusReply = (data) => 
    {
        var {commentId, status} = data; 
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedcomment = {...comment}
            if (modifiedcomment.id == commentId){
                modifiedcomment.status = status;
            }
            return modifiedcomment;
        });

        setAnnotationComments(modified);
    }

    const handleCommentReply = (data) => 
    {

        var {commentId, reply} = data;         
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedcomment = {...comment}
            if (modifiedcomment.id == commentId){
                modifiedcomment.replies.push(reply);
            }
            return modifiedcomment;
        });
        setAnnotationComments(modified);
    }


    const handleCommentReplyDeleted = (data) => {
        
        var {commentId, reply} = data;         
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedcomment = {...comment}
            if (modifiedcomment.id == commentId){
                // modifiedcomment.replies.push(reply);
                // fond the target comment 
                // find the target reply
                var index = modifiedcomment.replies.findIndex(x => x.id  === reply.id);
                modifiedcomment.replies.splice(index, 1)
            }
            return modifiedcomment;
        });
        setAnnotationComments(modified);
    }

    const handleCommentDeleted = (data) => {
        if (donotHanldeLiveUpdate.current) {
            return;
        }

        setCommentsDeleted(true);        
        deletedComments.current.push(data);
        setDeletedCommentCount(deletedComments.current.length)
    };


    const handleNewCommentAdded = (data) => {
        if (donotHanldeLiveUpdate.current) {
            return;
        }

        setHasNewComment(true);        
        newComments.current.push(data);
        setNewCommentCount(newComments.current.length)

    };

    const newCommentBannerClicked = (reason) => {
        setHasNewComment(false);
        var currentFrameModified = newComments.current.some(x => x.frameRange[0] === currentFrameNumberRef.current);
        const updated = annotationComments.concat(newComments.current);
        setAnnotationComments(updated);
        newComments.current = [];
        if (currentFrameModified) {
            // var annotationsInCurrentFrame = updated.filter( x=> x.frameRange[0]=== currentFrameNumberRef.current);
            redraw(updated, getIsSelectable(), currentFrameNumberRef.current);
        }
        
        var props = providerState;
        contributorsHelper.configureContributors(proofVersion, updated, contributors ,userId, true, props);
    };

    const deletedCommentsBannerClicked = () => {
        setCommentsDeleted(false);
        
        var modified = annotationComments.map((comment) => {  return comment; });

        var currentFrameModified ;
        deletedComments.current.forEach(deleted => {   
            var comment = modified.find(x => x.id  === deleted.commentId)
            currentFrameModified = comment.frameRange[0] === currentFrameNumberRef.current;
            var index = modified.findIndex(x => x.id  === deleted.commentId);
            modified.splice(index, 1)
        });

        setAnnotationComments(modified);
        deletedComments.current = [];
        
        if (currentFrameModified) {
            var annotationsInCurrentFrame = modified.filter( x=> x.frameRange[0]=== currentFrameNumberRef.current);
            redraw(modified, getIsSelectable(), currentFrameNumberRef.current);
        }
        var props = providerState;
        contributorsHelper.configureContributors(proofVersion, modified, contributors ,userId, false, props);
    }

    const toggleDrawingMode = (toggle) => {
        mainDrawingCanvas.toggleDrawingMode(toggle);
        toggle && mainDrawingCanvas.initFreeHand(userAnnotationColor);
    };

    const onObjectSelectionChanged = ((object) => {
        console.log(mainDrawingCanvas);
        console.log(providerState);

        var selected = object.target.uuid_parent;

        var selectedIndex = annotationComments.findIndex((x) => x.id === selected);

        if (selectedComment === selectedIndex) {
        } else {
            if (selectedIndex !== -1) {
                setSelectedComment(selectedIndex);
            }
        }

        //createArrow(object.selected[0]);
        onAnnotationSelectionChanged(object.selected[0]);
    });

    const redrawArrow = (containerZoom) => {            
        if(selectedComment === -1) { return; }

        setDisableArrow(true);

        setTimeout(()=>{
            redrawAnnotationCommentArrow(containerZoom);           
            setDisableArrow(false);
        }, DRAW_ARROW_DELAY);
    }

    const removeCanvasObjectById = (id) => {
        mainDrawingCanvas.removeObjectById(id);
    };

    const setRangeSelectorToCurrentFrame = () => {
        setRangeSelectorValue([currentFrameNumber, currentFrameNumber]);
    };

    const refreshComments = async () => {
        var payLoad = {
            proofId: videoMetadata.proofId,
            proofVersionId: videoMetadata.proofVersionId
        };
        var videoCommentsResponse = await videoCommentService.getVideoComments(payLoad);

        setAnnotationComments(videoCommentsResponse);
    };

    const seekToTime = (targetFrame) => {
        // if (currentFrameNumber !== targetFrame) {
        
        var seekTime = Number((targetFrame / videoMetadata.frameRate).toFixed(5));
        setCurrentTimeInSeconds(seekTime);
        videoPlayer.currentTime = seekTime;
        videoPlayer.pause();

        
        mainDrawingCanvas.clear();

        if(targetFrame !== currentFrameNumberRef.current){
            releaseCommentSelection();
        }
        

//         var selectedAnnotations = annotationComments
//             .filter((item) => item.frameRange[0] === targetFrame)
//             .map((item) => {
//                 return { ...item };
//             });
        let isUserPermittedToAdd = authorizationService.isUserPermitted(
            authorizationConstants.ProofEditor.KEY,
            authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
        );
//         selectedAnnotations.forEach((annotationComment) => {
//             let annotationObject = JSON.parse(annotationComment.annotations[0].annotationObject);
//             annotationObject.uuid = annotationComment.annotations[0].id;
//             annotationObject.uuid_parent = annotationComment.id;
//             const shape = {
//                 type: annotationComment.shape,
//                 ...annotationObject,
//                 action: ShapeAction.ADD,
//                 selectable: proofVersion.isLocked || !isUserPermittedToAdd ? false : true,
//             };

//             modifyAnnotationColor(shape, annotationComment.createdById, contributorsRef.current);
//             mainDrawingCanvas.drawShape(shape);
//         });
        // }
        var anns = findAllAnnotationsForCanvas(annotationComments, targetFrame);
        drawAllShapes(mainDrawingCanvas, anns, isUserPermittedToAdd);
        
        
    };

    const calculateArrowPoints = (annotations)=> {
        
        var points = [];
        let canvas = {};
        annotations.forEach(annotation => {
            canvas = mainDrawingCanvas;
            var object = canvas.getObjectById(annotation.id);
            var point = calculateVideoAnnotationCenterPoint(object, containerScale, canvas, isProofCompare, hasExtraTopBar);
            points = ([...points, point]);
        });

        setArrowStartPoints(points);               
    }

    // const findInRange = (commentItem) => {
    //     if (this.props.editingCommentId === commentItem.id) {
    //         return true;
    //     }        
    //     let insideRange = this.props.currentFrameNumber >= commentItem.frameRange[0]   
    //                       && this.props.currentFrameNumber <= commentItem.frameRange[1];        
    //     return insideRange;
    // }
    
    const findAllAnnotationsForCanvas = (commentsList, frameNumber) => {                
        var annotations = [];        
        commentsList.forEach((ac) => {
            ac.annotations.forEach((shape)=>{
                if(frameNumber >= shape.frameRange[0] && frameNumber <= shape.frameRange[1]){
                    annotations.push({parent: ac.id, createdById: ac.createdById ,...shape});
                }
            });
        });

        return annotations;
    }

    
    const redraw = (annotationComments, isSelectable, frameNumber) => {
        var anns = findAllAnnotationsForCanvas(annotationComments, frameNumber);
        drawAllShapes(mainDrawingCanvasRef.current, anns, isSelectable);
    };


    const onCommentAdd = async (shapeInfo) => {
        var uuid = shapeInfo.uuid;
        const annotationType = shapeInfo.type;
        var canvasObject = {
            ...shapeInfo
        };

        canvasObject.onDrawCompleted = null;
        var currentAbsoluteTime = videoPlayer.currentTime;
        var currentFrameNumber = Math.round(currentAbsoluteTime * videoMetadata.frameRate);
        var annotation = {
            uuid,
            uuid_parent: shapeInfo.uuid_parent,
            frameRange: [currentFrameNumber, currentFrameNumber],
            annotationType: annotationType,
            annotationObject: canvasObject,
            operationMode: 0
        };

        updateSelectedAnnotation(annotation); 
        setIsCommentAddMode(true);
        setRangeSelectorToCurrentFrame();
    };

    const onAnnotationMerge = async (shapeInfo, canvas, index) => {
        var uuid = shapeInfo.uuid;
        const annotationType = shapeInfo.type;     
        const canvasObject = {
            ...shapeInfo
        };
        canvasObject.onDrawCompleted = null;
        
        var annotation = {
            uuid,
            uuid_parent: shapeInfo.uuid_parent,
            frameRange: [currentFrameNumber, currentFrameNumber],
            annotationType: annotationType,
            annotationObject: canvasObject,
            operationMode: 0            
        };
        
        updateSelectedAnnotation(annotation);
        setIsCommentMerge(true);        
    };

    const updateSelectedAnnotation = (annotation) => {        
        var current = [...selectedAnnotation];
            
        var findIndex = current.findIndex(x=> x.uuid === annotation.uuid);        
        
        if(findIndex === -1){
            setSelectedAnnotation([...current, annotation]);
        }
        else {
            current[findIndex] = annotation;
            setSelectedAnnotation([...current]);
        }
    };

    

    const reScaleCanvasContainer = () => {        
        let canvasContainer = document.getElementById('mrnda-canvas-container');
        let video = videoPlayer;
        let containerHeight = canvasContainer.offsetHeight - 20;
        let containerWidth = canvasContainer.offsetWidth - 20;
        let ratio =
        containerHeight > video.videoHeight && containerWidth > video.videoWidth
            ? 1
            : calculateAspectRatioFit(
                video.videoWidth,
                video.videoHeight,
                containerWidth,
                containerHeight
            ).toFixed(2);
        let transformContainer = { transform: `scale(${ratio}, ${ratio})` };
        setContainerScale(ratio);
        setVideoPlayerContainerTransform(transformContainer);
    };


    const drawAllShapes = (drawingCanvas, selectedAnnotations, isUserPermittedToAdd, clearCanvas = true, removePrevious = false, color = null) => {


        if (clearCanvas) {
            drawingCanvas.clear();
        }

        selectedAnnotations.forEach((annotation) => {            
            let annotationObject = JSON.parse(annotation.annotationObject);
            annotationObject.uuid = annotation.id;
            annotationObject.uuid_parent = annotation.parent;
            const shape = {
                type: annotation.shapeType,
                ...annotationObject,
                action: ShapeAction.ADD,
                selectable: proofVersion.isLocked || !isUserPermittedToAdd ? false : true
            };

            additionalProps(shape, annotation.createdById);
            modifyAnnotationColor(shape, annotation.createdById, contributorsRef.current, color, annotation.createdById === userId);

            if (removePrevious) {
                drawingCanvas.removeObjectById(annotation.id)
            }

            drawingCanvas.drawShape(shape);

        });
    }
    

    const drawSingleAnnotation = (
        pageNumber,
        drawableAnnotation,        
        clearPage = false,
        keepSelected = true
    ) => {

        if (clearPage) {
            mainDrawingCanvasRef.current.clear();
        }


        let isSelectable = getIsSelectable();
        
        let annotationObject = JSON.parse(drawableAnnotation.annotationObject);
        annotationObject.uuid = drawableAnnotation.id;
        annotationObject.uuid_parent = drawableAnnotation.parent;
        const shape = {
            type: drawableAnnotation.shapeType,
            ...annotationObject,
            action: ShapeAction.ADD,
            selectable: isSelectable
        };

        additionalProps(shape, drawableAnnotation.createdById);
        modifyAnnotationColor(shape, userId, contributorsRef.current);
        mainDrawingCanvasRef.current.drawShape(shape);       
        if(keepSelected === true) {
            mainDrawingCanvasRef.current.selectObjectById(annotationObject.uuid);
        }
    };

    const selectAnnotationOnCanvas = () => {        
        if (annotationComments.length === 0 || selectedComment === -1) {
            return;
        }
        var selectedCommentObj = annotationComments[selectedComment];
        var annotationIds = selectedCommentObj.annotations;
        var canvas = mainDrawingCanvas;

        var isSelectable = getIsSelectable();

        if (canvas !== undefined && isSelectable) {
            canvas.selectObjectById(annotationIds[0].id);
        }
        
        if(!isMobile){
            calculateArrowPoints(annotationIds, canvas);                     
        }
    } 

    const setCommentArrowPointFinders = (key,value) => {                 
        commentArrowPointFindersRef.current.set(key, value);        
    }

    const deleteCommentArrowPointFinder = (key) => {   
        commentArrowPointFindersRef.current.delete(key);        
    }

    const updateObjectArrowPoint = (object, canvasContainer) => {
        //REFEC
        var point = calculateVideoAnnotationCenterPoint(object, containerScale, canvasContainer , isProofCompare, hasExtraTopBar, setHasExtraTopBar);        
        var index = arrowStartPoints.findIndex(x => x.uuid === object.uuid);

        if(index === -1) {
            setArrowStartPoints([...arrowStartPoints, point]);
        }
        else{
            var points = [...arrowStartPoints];
            points[index] = point;
            setArrowStartPoints(points);
        }
    }

    const calculateCommentArrowPoint = (commentIndex, fromScroll = false, bounds) => {        
        var comment = annotationComments[commentIndex === undefined? selectedComment: commentIndex];
        if(comment === undefined) { return;}        
        var finder = commentArrowPointFindersRef.current.get(comment.id);
        finder(fromScroll, bounds);
    }

    const redrawAnnotationCommentArrow = () => {
        calculateArrowPoints(annotationComments[selectedComment].annotations)
    }
    const releaseCommentSelection = () => {
        
        // We wont release the selection if composite mode
        if (isComposite === true) { return; } 

        // Other wise just clear selection
        setSelectedComment(-1);
        setDisableArrow(true);            
    }



    const updateCurrentAnnotationOnCanvas = (updates) => {

        if(isPreDrawing(currentAnnotationOnCanvas) === true) {

            var newProps = {...currentAnnotationOnCanvas};

            var incoming = {};
            updates.forEach((update)=> {
                incoming[update.prop] = update.value;
            })


            setCurrentAnnotationOnCanvas({...newProps, ...incoming});

            return;
        }

        updates.forEach((update)=> {
            currentAnnotationOnCanvas.set(update.prop, update.value);
            if(currentAnnotationOnCanvas.type === drawingConstants.ANNOTATION_TYPE.strikeOut.typeName){                
                
                //Strike has  multiple objects grouped together 
                //So we need to update all the objects inside it.
                currentAnnotationOnCanvas._objects.forEach(element => {
                    element.set(update.prop, update.value);
                })
            }
        });

        //updateSelectedAnnotation(currentAnnotationOnCanvas);        
        
        if(isPostDrawing(currentAnnotationOnCanvas) === true){
            reRenderChanges();
            return;
        }

        //NOTE : This may be a bug
        var copied  = [...annotationComments];
        copied[selectedComment].annotations[0].annotationObject = JSON.stringify(currentAnnotationOnCanvas);
        setAnnotationComments(copied); 

        var changedComment = copied[selectedComment];
        updateAnnotationMarker(changedComment, copied);

        reRenderChanges();
    }

    const updateAnnotationMarker = (changedComment, changedComments) => {
        var currentMarkers = [...markers];
        var markerIndex = currentMarkers.findIndex(x=> x.id === changedComment.id);         
        var modifiedMarker = {...currentMarkers[markerIndex]};
        if(modifiedMarker.startFrame !== changedComment.frameRange[0]){
            //start frame changed recalculate all markers
            console.log('Markers start frame changed');                                    
            var newMarkers =  createMarkers(changedComments, videoMetadata.frameCount, []);
            currentMarkers = [...newMarkers];
            setCurrentFrameNumber(changedComment.frameRange[0]);
        
        }else {
            modifiedMarker.comment = changedComment;
            currentMarkers[markerIndex] = modifiedMarker;
        }

        
        setMarkers(currentMarkers);       
    }

    const reRenderChanges  = () => {
        var canvas = mainDrawingCanvas;
        // NOTE: After changing text object modified event doesn't  fire in
        //       fabric canvas. So we are triggering the event manually.  
        canvas.fabricCanvas.trigger('object:modified', {target: currentAnnotationOnCanvas});
         
        // NOTE: After changing the text fabric canvas doesn't show it 
        //       So we need to call render all manually.
        canvas.renderAll();
    }

    const startDrawing = (shapeType, index, onShapeDrawComplete, isFreehand = false) => {
        if (!isFreehand) {
            //setIsCommentAddMode(false);
        } else {
            toggleDrawingMode(true, index);
        }
        let uuid = uuidv4();
        setCurrentUuid(uuid);
        let shape = {
            uuid: uuid,
            uuid_parent: uuidv4(),
            type: shapeType,
            action: ShapeAction.START,
            onDrawCompleted: onShapeDrawComplete            
        };  
        
        if(shapeType !== drawingConstants.ANNOTATION_TYPE.commentStamp.typeName){
            shape = { ...shape, ...currentAnnotationOnCanvasRef.current}
        }

        additionalProps(shape, userId);
        modifyAnnotationColor(shape, userId, contributorsRef.current);
        
        console.log('initiating draw ->  shape type : ', shapeType);
        console.log('initiating draw ->  shape  : ', shape);

        mainDrawingCanvas.drawShape(shape);
    }

    const isPreDrawing = (shapeProps) => {
        return shapeProps.uuid === undefined;
    } 
    const isPostDrawing = (shapeProps) => {
        var tt = annotationComments.some(x => x.id === shapeProps.uuid_parent)
        return !tt;
    }

    const onSelectedAnnotationCleared = (shape) => {        
        setShowShapeEditingTools(false);
        setCurrentAnnotationOnCanvas(undefined);        
    }

    const onAnnotationSelectionChanged = (shape) => {
        var comment = annotationComments.find(x => x.id === shape.uuid_parent);

        if (comment === undefined || comment === null || comment?.createdById === userId) {
            setShowShapeEditingTools(true);
            setCurrentAnnotationOnCanvas(shape);
        }
        else {
            onSelectedAnnotationCleared();
        }
    }
    
    const clearShapeTools = () => {
        setCurrentAnnotationOnCanvas(undefined); 
        setShowShapeEditingTools(false);
    }

    const fitToHeight = () => {        
        let canvasContainer = document.getElementById('mrnda-canvas-container');
        let video = videoPlayer;
        let containerHeight = canvasContainer.offsetHeight - 20;
        let ratio = containerHeight / video.videoHeight;
        let transformContainer = { transform: `scale(${ratio}, ${ratio})` };
        setContainerScale(ratio);
        setVideoPlayerContainerTransform(transformContainer);

    }

    const clearCommentSelection = () => {
        console.log("Removing selection from canvas and comment list");
        
        mainDrawingCanvas.clearSelection(); 
        releaseCommentSelection();
    }

    const fitToWidth = () => {
        
        let canvasContainer = document.getElementById('mrnda-canvas-container');
        let video = videoPlayer;
        let containerWidth = canvasContainer.offsetWidth - 60;
        let ratio = containerWidth / video.videoWidth;
        let transformContainer = { transform: `scale(${ratio}, ${ratio})` };
        setContainerScale(ratio);
        setVideoPlayerContainerTransform(transformContainer);

    }

    const saveVolume = (volume)=> {
        volumeHelper.set(volume);
        setVolume(volume); 
    }

    const onDrawingToolClicked = (shapeObject) => {
        console.log('drawing tool selected : ' +  shapeObject.typeName);
        setIsPanningSelected(false);
        setDisableArrow(true);
        setSelectedTool(shapeObject.typeName);
        setInitDrawing(true);
        setIsComposite(true);
        // props.hoverCursor();
        if (shapeObject.typeName === highlight.typeName) {
            //onHighlightPreDraw();
        } else {
            clearShapeTools();
        }

        startDrawing(shapeObject.typeName, 0, onShapeDrawComplete, false);
    };

    const onShapeDrawComplete = (shape) => {    
        if (isComposite === true && selectedComment !== -1) {
            //New drawing flow
            console.log('shape draw complete.', shape);

            if (shape && shape.action === ShapeAction.ADD) {
                onAnnotationMerge(shape, editorArea.current, 0);
                toggleDrawingMode(false, 0);
            }
            setSelectedTool('');
            setInitDrawing(false);

        } else {
            console.log('shape draw complete.', shape);
            if (shape && shape.action === ShapeAction.ADD) {
                setIsCommentAddMode(true);
                onCommentAdd(shape, editorArea.current, 0);
                toggleDrawingMode(false, 0);
            }
            setSelectedTool('');
            setInitDrawing(false);
        }
    };
    

    const onPrepareForDrawing = () => {
        if (!initDrawing) {
            return;
        }
        let isFreehand = selectedTool === drawingConstants.ANNOTATION_TYPE.path.typeName;
        startDrawing( selectedTool, 0, onShapeDrawComplete, isFreehand );

    };

    const onCleanUpDrawing = () => {
        if (!initDrawing) {
            return;
        }
        console.log('Mouse Exiting....');
        console.log(`Current uuid for drawing - ${currentUuid}`);
        mainDrawingCanvas.removeObjectById(currentUuid);
        mainDrawingCanvas.removeEvents();
        console.log(`Object ${currentUuid} has been removed from canvas`);
        setCurrentUuid(null);
    };

    const handleUpdateUserPreferences = async (inComingUserPreferences, updateServer = true) => {
        var merged = {...userPreferences, ...inComingUserPreferences};
        updateUserPreferences(merged, updateServer);
        if(updateServer){
           await userService.updateUserPreferences(merged);
        }
    }

    const providerState = {
        proofVersion,

        mainDrawingCanvas,
        videoPlayer,
        videoMetadata,

        timeMode,
        setTimeMode,

        annotationComments,
        selectedAnnotation,
        currentTimeInSeconds,
        currentFrameNumber,
        currentTimeInText,
        currentTimeInTimeCode,

        isCommentAddMode,
        canvasTextEditEntered,
        rangeSelectorValue,

        setProofVersion,
        setMainDrawingCanvas,
        setVideoPlayer,
        setVideoMetadata,

        setAnnotationComments,
        setSelectedAnnotation,
        setCanvasTextEditEntered,
        setIsCommentAddMode,
        setCurrentTimeInSeconds,
        setRangeSelectorValue,
        setRangeSelectorToCurrentFrame,

        toggleDrawingMode,
        removeCanvasObjectById,
        refreshComments,
        seekToTime,
        onCommentAdd,

        transcriptions,
        setTranscriptions,

        sidebarTabView,
        setSidebarTabView,
        currentCaption,
        setCurrentCaption,
        videoPlayerContainerTransform,
        reScaleCanvasContainer,
        connectionStatus,
        hasNewComment,
        newCommentBannerClicked,
        donotHanldeLiveUpdate,
        changeLiveUpdateTarget,
        newCommentCount,
        commentsDeleted,
        deletedCommentsBannerClicked,
        deletedCommentCount,
        userAnnotationColor, 
        setUserAnnotationColor,  
        selectAnnotationOnCanvas,  
        userId, 
        setUserId,
        userFullName, 
        setUserFullName,
        contributors, 
        setContributors,
        selectedComment, 
        setSelectedComment,
        arrowPoint1, setArrowPoint1,
        arrowPoint2, setArrowPoint2,
        disableArrow, setDisableArrow,
        containerScale,
        calculateCommentArrowPoint ,
        setCommentArrowPointFinders,
        deleteCommentArrowPointFinder,
        redrawAnnotationCommentArrow,
        arrowColor, setArrowColor,
        isProofCompare, 
        setIsProofCompare,
        updateCurrentAnnotationOnCanvas,
        showShapeEditingTools,
        setShowShapeEditingTools,
        currentAnnotationOnCanvas,
        onSelectedAnnotationCleared,
        onAnnotationSelectionChanged,
        commentText, 
        setCommentText,
        editAllowedComments, setEditAllowedComments,
        editAllowedReplies, setEditAllowedReplies,
        containerZoom, setContainerZoom,
        containerScale, setContainerScale,
        fitToHeight,
        fitToWidth,
        clearCommentSelection,
        volume,
        saveVolume,
        clearShapeTools,
        setCurrentAnnotationOnCanvas,
        selectedTool, 
        setSelectedTool,
        initDrawing, 
        setInitDrawing,
        startDrawing,
        currentUuid, 
        setCurrentUuid,
        isComposite, setIsComposite,
        isCommentMerge, setIsCommentMerge,
        releaseCommentSelection,
        isComposite, setIsComposite,
        isCommentMerge, setIsCommentMerge,
        onAnnotationMerge,
        arrowStartPoints, setArrowStartPoints,
        updateObjectArrowPoint,
        drawSingleAnnotation,
        currentTool, setCurrentTool,
        isPanningSelected, setIsPanningSelected,
        onObjectSelectionChanged,
        setPlayerArea,
        setEditorArea,
        proofSettings, setProofSettings,
        hotKeyEnabled, setHotKeyEnabled,
        annotationCommentsRef,
        editingCommentId,
        setEditingCommentId,
        markers, setMarkers, markersRef,
        updateAnnotationMarker,
        commentStatuses, setCommentStatues,
        onDrawingToolClicked,
        selectedToolbarAction, setSelectedToolbarAction,
        onPrepareForDrawing,
        onCleanUpDrawing,
        canvasLimitExceeded, 
        setCanvasLimitExceeded,
        hasExtraTopBar, setHasExtraTopBar,
        userPreferences, handleUpdateUserPreferences
    };

    return (
        <VideoAnnotationContext.Provider value={providerState}>
            {children}
        </VideoAnnotationContext.Provider>
    );
}

export { VideoAnnotationProvider };
