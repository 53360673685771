import React from 'react';

export const CommentIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '21px',
                width: '21px',
                ...mergedStyle
            }}
            {...restProps}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.75036 12.6922C3.60484 12.608 3.42676 12.6026 3.27639 12.6778L3.5 13.125L3.27633 12.6778L3.27621 12.6779L3.2758 12.6781L3.27425 12.6789L3.26837 12.6818L3.24582 12.6931L3.16065 12.7361C3.08724 12.7732 2.98209 12.8267 2.85461 12.8921C2.59984 13.023 2.25499 13.2023 1.89576 13.3957C1.6895 13.5068 1.47712 13.6232 1.2735 13.7384C1.2898 13.6804 1.30612 13.6224 1.32243 13.5644C1.60006 12.5776 1.87305 11.6073 1.98721 11.1124C2.01954 10.9722 1.98994 10.8249 1.90597 10.7081C1.10649 9.59607 0.5 8.37162 0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C5.79774 13.5 4.69174 13.2368 3.75036 12.6922Z"
                    stroke="currentColor"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    );
};
