import React from 'react';

export const TextboxIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '21px',
                width: '21px',
                ...mergedStyle
            }}
            {...restProps}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="currentColor" />
                <rect x="4" y="4" width="8" height="1" rx="0.5" fill="currentColor" />
                <path
                    d="M8.5 5L8.5 12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5L7.5 5L8.5 5Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};
