import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { ShapeAction, drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { withDocumentAnnotationConsumer } from '../Context/DocumentAnnotationConsumer';
import { uuidv4 } from '../DocumentAnnotationUtil';
import { utilService, authorizationService } from '../../../../_helpers';
import { proofService } from '../../proof.service';
import { authorizationConstants } from '../../../_shared';
import { documentAnnotationConstants } from '../Context/documentAnnotation.constants';
import { calculateNewZoom } from '../DocumentAnnotationUtil';
import ContributorsComponent from '../../ContributorsComponent';

import { userService } from '../../../User/user.service';
import { TextboxEditingTools } from './TextboxEditingTools';
import { AnnotationColorChangeTools } from './AnnotationColorChangeTools';
import { EditorToolboxZoom } from './EditorToolboxZoom';
import { HighlightColorChangeTools } from './HighlightColorChangeTools';
import { TEXT_EXTRACT_STATUS, TEXT_RECOGNITION_SETTINGS_TYPES } from '../../proof.constants';
import PlayIcon from '../../../_shared/Icon/PlayIcon';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import { RectangleIcon } from '../../../_shared/Icon/DesktopToolsIcons/RectangleIcon';
import { CircleIcon } from '../../../_shared/Icon/DesktopToolsIcons/CircleIcon';
import { TextboxIcon } from '../../../_shared/Icon/DesktopToolsIcons/TextboxIcon';
import { FreehandIcon } from '../../../_shared/Icon/DesktopToolsIcons/FreehandIcon';
import { ArrowIcon } from '../../../_shared/Icon/DesktopToolsIcons/ArrowIcon';
import { CommentIcon } from '../../../_shared/Icon/DesktopToolsIcons/CommentIcon';
import { PinIcon } from '../../../_shared/Icon/DesktopToolsIcons/PinIcon';
import { HighlightIcon } from '../../../_shared/Icon/DesktopToolsIcons/HighlightIcon';
import { StrikeThroughIcon } from '../../../_shared/Icon/DesktopToolsIcons/StrikeThroughIcon';
import { TextHighlightIcon } from '../../../_shared/Icon/DesktopToolsIcons/TextHighlightIcon';

const DRAW_ARROW_DELAY = 300;

const DocumentEditorToolbox = (props) => {
    const {
        proofVersion: { status },
        proofSettings
    } = props;
    const { ANNOTATION_TYPE } = drawingConstants;
    const { ZOOM_STEP, ZOOM_SELECTIONS: zoomSelections } = documentAnnotationConstants;
    const [ showOpacityColorPicker, setShowOpacityColorPicker] = useState(false);
    const { isMobile } = useScreenSizes();
    const onDrawingToolClicked = async (shapeObject) => {
        props.setIsPanningSelected(false);
        props.setSelectedTool(shapeObject.typeName);
        props.setIsComposite(true);
        await props.changeInitDrawing(true);

        // props.hoverCursor();
        if (
            shapeObject.typeName === highlight.typeName ||
            shapeObject.typeName === textHighlight.typeName
        ) {
            onHighlightPreDraw(shapeObject.typeName);
        } else {
            props.clearShapeTools();
        }
    };

    const onHighlightPreDraw = (typeName) => {
        props.setCurrentAnnotationOnCanvas({
            type: typeName
        });
        props.setShowShapeEditingTools(true);
    };

    const onCommentStampToolClicked = async (shapeObject) => {
        props.setIsPanningSelected(false);
        props.setIsComposite(true);
        props.setDisableArrow(true);
        props.setSelectedTool(shapeObject.typeName);
        await props.changeInitDrawing(true);
        props.startDrawing(
            shapeObject.typeName,
            props.selectedCanvas,
            onCommentStampDrawComplete,
            false
        );
        props.clearShapeTools();
    };

    const onCompareColorChange = async (color) => {
        var request = {
            proofId: props.proofVersion.proofId,
            Preference: {
                userID: props.userId,
                color: color,
                userInitial: utilService.getUserInitial(props.userFullName)
            }
        };

        let commentResponse = await proofService.updateAnnotationColorPreference(request);
        var newContributors = props.contributors.map((e, i) => {
            let newItem = { ...e };
            i === 0 && (newItem.color = color);
            return newItem;
        });
        props.setContributors(newContributors);
        props.reDrawCurrentUserAnnotations(color);
        props.setUserAnnotationColor(color);
    };

    const onCommentStampDrawComplete = (shape) => {
        console.log(props.isComposite);
        if (props.selectedComment === -1) {
            console.log('shape draw complete.', shape);
            if (shape && shape.action === ShapeAction.ADD) {
                props.setIsCommentAddMode(true);
                props.mainDrawingCanvas[props.selectedCanvas].selectObjectById(shape.uuid);
                props.onCommentAdd(shape, null, props.selectedCanvas);
                props.toggleDrawingMode(false, props.selectedCanvas);
            }
            props.setSelectedTool('');
            props.setInitDrawing(false);
        } else {
            console.log('shape draw complete.', shape);
            if (shape && shape.action === ShapeAction.ADD) {
                props.onAnnotationMerge(shape, null, props.selectedCanvas);
                props.toggleDrawingMode(false, props.selectedCanvas);
            }
            props.setSelectedTool('');
            props.setInitDrawing(false);
        }
    };
    const {
        rect,
        circle,
        textbox,
        path,
        arrow,
        commentStamp,
        pinPointer,
        highlight,
        strikeOut,
        textHighlight
    } = ANNOTATION_TYPE;

    const getItemClassnames = (shapeType) => {
        return cx({
            'mrnda-video-editor-area-toolbox-shapes--item': true,
            active: shapeType === props.selectedTool
        });
    };

    const onZoomInClicked = () => {
        props.setLastZoom(props.containerZoom);
        let containerZoom = props.containerZoom + ZOOM_STEP;
        var scrollTop = props.editorArea.current.scrollTop;
        props.documentContainer.current.style.transformOrigin = `center ${scrollTop}px`;
        props.setContainerZoom(containerZoom);
    };
    const onZoomOutClicked = () => {
        props.setLastZoom(props.containerZoom);
        let containerZoom = props.containerZoom - ZOOM_STEP;
        if (containerZoom < 1) {
            return;
        }
        props.setContainerZoom(containerZoom);
    };

    const onFitWidthClicked = () => {
        console.log('Page container fitted on screen');
        var parent = props.editorArea.current;
        var currentElement = props.canvasContainers[props.selectedCanvas];
        var availableWidth = parent.offsetWidth - 80;
        var currentWidth = currentElement.offsetWidth;
        var zoom = calculateNewZoom(availableWidth, currentWidth, props.containerZoom);

        if (zoom != undefined) {
            props.setContainerZoom(zoom);
        }
    };

    var isUserPermittedForAdd = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
    );

    const isTexExtractNotAllowed = status?.textExtractStatus == TEXT_EXTRACT_STATUS.IN_PROGRESS;

    const isTextExractAvailable =
        proofSettings?.textRecognitionSettingType === TEXT_RECOGNITION_SETTINGS_TYPES.auto.value ||
        proofSettings?.textRecognitionSettingType ===
            TEXT_RECOGNITION_SETTINGS_TYPES.ondemand.value;

    const toolboxStyle = isMobile 
            ? {} 
            : props.compactNavbar 
                ? { flex: '0 0 52px', height: '52px', border : 'none', boxShadow: 'none' }
                : { flex: '0 0 52px' };    
    return (
        <div style={toolboxStyle} className={`mrnda-document-editor-area-toolbox`}>
            <div
                className={`mrnda-document-editor-area-toolbox-contributors ${
                    props.isCommentAddMode || props.proofVersion.isLocked || !isUserPermittedForAdd
                        ? ' authorization-disabled'
                        : ''
                }`}>
                <ContributorsComponentHOC />
            </div>

            <div className={`mrnda-document-editor-area-toolbox-shapes`}>
                <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={cx({
                        'authorization-disabled':
                            props.proofVersion.isLocked || !isUserPermittedForAdd,
                        'skip-comment-click-outside': true
                    })}>
                    <div className={getItemClassnames(rect.typeName)} title={rect.name}>
                        {/* <i onClick={() => onDrawingToolClicked(rect)} className={rect.icon}></i> */}
                        <RectangleIcon onClick={() => onDrawingToolClicked(rect)} />
                    </div>
                    <div className={getItemClassnames(circle.typeName)} title={circle.name}>
                        {/* <i onClick={() => onDrawingToolClicked(circle)} className={circle.icon}></i> */}
                        <CircleIcon onClick={() => onDrawingToolClicked(circle)} />
                    </div>
                    <div
                        style={{}}
                        className={getItemClassnames(textbox.typeName)}
                        title={textbox.name}>
                        {/* <i
                            onClick={() => onDrawingToolClicked(textbox)}
                            className={textbox.icon}></i> */}
                        <TextboxIcon onClick={() => onDrawingToolClicked(textbox)} />
                    </div>
                    <div style={{}} className={getItemClassnames(path.typeName)} title={path.name}>
                        {/* <i onClick={() => onDrawingToolClicked(path)} className={path.icon}></i> */}
                        <FreehandIcon onClick={() => onDrawingToolClicked(path)} />
                    </div>
                    <div
                        style={{}}
                        className={getItemClassnames(arrow.typeName)}
                        title={arrow.name}>
                        {/* <i onClick={() => onDrawingToolClicked(arrow)} className={arrow.icon}></i> */}
                        <ArrowIcon onClick={() => onDrawingToolClicked(arrow)} />
                    </div>
                    <div
                        style={{ marginTop: 2 }}
                        className={getItemClassnames(commentStamp.typeName)}
                        title={commentStamp.name}>
                        {/* <i
                            onClick={() => onCommentStampToolClicked(commentStamp)}
                            className={commentStamp.icon}></i> */}
                        <CommentIcon onClick={() => onCommentStampToolClicked(commentStamp)} />
                    </div>

                    <div
                        className={getItemClassnames(pinPointer.typeName)}
                        title={pinPointer.name}
                        onClick={() => onDrawingToolClicked(pinPointer)}>
                        <PinIcon
                            color={
                                pinPointer.typeName === props.selectedTool
                                    ? 'var(--primary-color)'
                                    : 'var(--primary-text-color)'
                            }
                        />
                    </div>

                    <div
                        style={{ marginTop: 2 }}
                        className={getItemClassnames(highlight.typeName)}
                        title={'Highlight'}
                        onClick={() => onDrawingToolClicked(highlight)}>
                        <HighlightIcon />
                    </div>

                    <div
                        style={{ marginTop: 2 }}
                        className={getItemClassnames(strikeOut.typeName)}
                        title={'Strikeout'}
                        onClick={() => onDrawingToolClicked(strikeOut)}>
                        <StrikeThroughIcon />
                    </div>

                    {isTextExractAvailable && utilService.isHTMLLiveProof(props.proofVersion) !== true && (
                        <span
                            title={
                                isTexExtractNotAllowed
                                    ? 'Text Recognition is in progress...'
                                    : 'Text Highlight'
                            }>
                            <div
                                style={{ marginTop: 2 }}
                                className={`${getItemClassnames(textHighlight.typeName)} ${
                                    isTexExtractNotAllowed ? 'authorization-disabled' : ''
                                }`}
                                onClick={() => onDrawingToolClicked(textHighlight)}>
                                <TextHighlightIcon />
                            </div>
                        </span>
                    )}
                </div>
                {/* NOTE: Kept if we add shape modifying tools later */}
                <div
                    id="toolbox-shape-tools"
                    style={{ display: 'flex' }}
                    className={
                        props.proofVersion.isLocked || !isUserPermittedForAdd
                            ? ' authorization-disabled'
                            : ''
                    }>
                    {props?.currentAnnotationOnCanvas?.type === highlight.typeName ||
                    props?.currentAnnotationOnCanvas?.type === textHighlight.typeName ? (
                        <HighlightColorChangeToolsHOC />
                    ) : (
                        <AnnotationColorChangeToolsHOC />
                    )}
                </div>
            </div>

            <div id="toolbox-zoom-tools" className="mrnda-document-editor-area-toolbox-tools">                                        
                <EditorToolboxZoomHOC
                    getItemClassnames={getItemClassnames}
                    zoomSelections={zoomSelections}
                    setContainerZoom={props.setContainerZoom}
                    containerZoom={props.containerZoom}
                    onZoomInClicked={onZoomInClicked}
                    onZoomOutClicked={onZoomOutClicked}
                    onFitWidthClicked={onFitWidthClicked}
                    isPanningSelected={props.isPanningSelected}
                    setIsPanningSelected={props.setIsPanningSelected}
                    isInteractiveSelected={props.isInteractiveSelected }
                    setIsInteractiveSelected={props.setIsInteractiveSelected} 
                    fitToSpace={props.fitToSpace}
                    selectedCanvas={props.selectedCanvas}
                    setSelectedTool={props.setSelectedTool}
                    onZoomRangeChanged={(selectedOption) => {
                        props.setContainerZoom(selectedOption.value);
                    }}
                />
            </div>
        </div>
    );
};
const ContributorsComponentHOC = withDocumentAnnotationConsumer(ContributorsComponent);
const AnnotationColorChangeToolsHOC = withDocumentAnnotationConsumer(AnnotationColorChangeTools);
const HighlightColorChangeToolsHOC = withDocumentAnnotationConsumer(HighlightColorChangeTools);
const EditorToolboxZoomHOC = withDocumentAnnotationConsumer(EditorToolboxZoom);
const hoc = withDocumentAnnotationConsumer(DocumentEditorToolbox);
export { hoc as DocumentEditorToolbox };
