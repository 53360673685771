import React, { useEffect, useState, useRef, useCallback } from 'react';
import './ProofDocumentEditor.scss';
import debounce from 'lodash.debounce';
import { DocumentAnnotationComment } from './Subs/DocumentAnnotationComment';
import { DocumentEditorToolbox } from './Subs/DocumentEditorToolbox';
import { DocumentPlayerWithCanvas } from './Subs/DocumentPlayerWithCanvas';
import { DocumentAnnotationProvider } from './Context/DocumentAnnotationProvider';
import { withAnnotationConsumer, withDocumentAnnotationConsumer } from './Context/DocumentAnnotationConsumer';
import { proofService } from '../proof.service';
import { documentCommentService } from './documentComment.service';
import { userService } from '../../User';
import { s3Service, evaporateS3Config, ConfirmationModal } from '../../_shared';
import { config, authHeader, utilService, history, store, contributorsHelper } from '../../../_helpers';
import { usePanning } from '../../../_helpers/hooks/usePanning';
import { tenantService } from '../../Tenant';
import { Prompt } from 'react-router-dom';
import { alertActions } from '../../App';
import { connect } from 'react-redux';
import { useTheme } from '../../App/Theme/ThemeContext';
import { ThemeChanger } from '../../App/Theme/ThemeChanger';

import cx from 'classnames';
import {
    convertZoomToScale,
    calculateNewZoom,
    bringToView
} from '../DocumentAnnotation/DocumentAnnotationUtil';
import scrollIntoView from 'scroll-into-view-if-needed';
import { directoryConstants } from '../directory.constants';
import AnnotationCommentArrow from '../AnnotationCommnetArrow';
import { commentHelper } from '../../../_helpers/commentHelper';
import { DocumentEditorThumbnails } from './Subs/DocumentEditorThumbnails';
import { useField } from 'formik';
import EditorLoading from '../EditorLoading';
import { isScrolledIntoView } from '../../../_helpers/scrollHelper';
import { EditorType, PROOF_OPEN_MODE, PROOF_TYPE } from '../proof.constants';
import { SelectedShapeIndicator } from './SelectedShapeIndicator';
import { commentStatusService } from '../../Settings/CustomStatus/services/commentStatus.service';
import { useScreenSizes } from '../../responsive/useScreenSizes';
import { conditionalClassName } from '../../responsive/responsiveUtils';
import { VideoEditorToolboxMobile } from '../VideoAnnotation/Subs/VideoEditorToolboxMobile';
import DesktopBreakpoint from '../../responsive/DesktopBreakpoint';
import ProofEditor from '../ProofEditor';
import { WrappedInsidePrimaryNav } from '../../_shared/Nav/withPrimaryNav';
import { isCompactNav, isRegularNav } from '../../../_helpers/navbarUtil';

export const RequestProofDocumentEditor = (props) => {

    return (
        <DocumentAnnotationProvider>
            <ProofDocumentEditorWrapperHOC {...props} fromRequest = {true}></ProofDocumentEditorWrapperHOC>
        </DocumentAnnotationProvider>
    );
};

const ProofDocumentEditor = (props) => {

    return (
        <DocumentAnnotationProvider>
            <ProofDocumentEditorWrapperHOC {...props}></ProofDocumentEditorWrapperHOC>
        </DocumentAnnotationProvider>
    );
};


const ProofDocumentEditorWrapper = (props) => {
    const { alert } = props;
    const [showExitConfirmModal, setShowExitConfirmModal] = useState(false);
    const [nextLocation, setNextLocation] = useState({});    
    const [isViewOnly, setIsViewOnly] = useState(false); 
    const [editMode, setEditorMode] = useState(false);    

    const pagesContainer = useRef(null);
    const editorArea = useRef(null);
    const xScrollPositionRef = React.useRef(0);
    const yScrollPositionRef = React.useRef(0);
    const { } = usePanning(pagesContainer.current,editorArea.current, props.isPanningSelected);
    const { isMobile } = useScreenSizes();   

    const compactNavbar = alert.isHideTopbar !== true && isCompactNav();

    useEffect(() => {
        if (isMobile) {
            props.dispatch(alertActions.hideTopbar(true, true));
            props.setEditorType(EditorType.Paged);
        } else {
            props.setEditorType(EditorType.Scrollable);
            let hideTopbar = props.fromRequest === true;     
            props.dispatch(alertActions.hideTopbar(hideTopbar, hideTopbar));
        }
    }, [isMobile, props.fromRequest]);
    useEffect(() => {
        let proofId = undefined;
        let versionId = undefined;
        let compareMode = false;
        if (props.match) {
            proofId = props.match.params.proofid;
            versionId = props.match.params.versionid;
            setEditorMode(true);

        }
        else if (props.compareVersion) {
            compareMode = true;
            proofId = props.compareVersion.proofId;
            versionId = props.compareVersion.id;
            props.setIsProofCompare(true);
            props.setEditorRef(editorArea.current, props.side);
        }
        else if (props.viewerVersion) {            
            proofId = props.viewerVersion.proofId;
            versionId = props.viewerVersion.id;  
            setIsViewOnly(true);          
        }
        else if (props.mode === PROOF_OPEN_MODE.Editor) {
            setEditorMode(true);
            proofId = props.proofVersion.proofId;
            versionId = props.proofVersion.id;              
        }

        if (!proofId || !versionId) {
            history.push('./dashboard/folders');
            return;
        }

        async function fetchInitData() {
            let proofVersions = await proofService.getProofVersions(proofId);
            if (!proofVersions) {
                history.push('./dashboard/folders');
                return;
            }
            var proofVersionsArr = Object.values(proofVersions);
            var latestVersion = proofVersionsArr.sort(
                (a, b) => b.versionNumber - a.versionNumber
            )[0];
            var isLatest = latestVersion.id === versionId;

            if (!isLatest) {
            }
            let proofVersion = { ...proofVersions[versionId] };



            if (compareMode) {
                proofVersion.isLocked = true;
            }

            // if (proofVersion.mediaType === PROOF_TYPE.HTMLLive) {
            //     var pagesCpy = proofVersion.pages;
            //     pagesCpy[0].height = "743"; 
            //     pagesCpy[0].width = "1333";    

            //     proofVersion.pages = pagesCpy;
            // }

            props.setProofVersion(proofVersion);


            document.title = `${proofVersion.name} - Brandshare Collaboration`;

            if (/*proofVersion.isLocked*/ false) {
                window.onbeforeunload = undefined;
            } else {
                window.onbeforeunload = () => {
                    window.onbeforeunload = () => {
                        utilService.getIsEditorDirty()
                    };
                };
            }
            var proofSettings = await tenantService.getProofSettings();
            var { statuses } = await commentStatusService.getAllStatus(false);
            props.setCommentStatues(statuses);            
            props.setProofSettings(proofSettings);
            
            let { authData: currentUser } = await userService.getCurrentUser();

            var fileMetadata = {
                durationInSeconds: 64.397667,
                fileURL:
                    'https://d20x224i6siear.cloudfront.net/5f8d6d25eb250806348b6231/5ff6bb22225a15742848160e/5ff6bb22225a15742848160d/5ff6bb22225a15742848160d.mp4?Expires=1611647905&Signature=dTxFUIsaF9TWZaIptCSkbe8KYSKn7Y4IwGuQal0ammWEsF3hVaGn8335LaXlRo-Dj7LnRrxA8QHpNgfFz1IXYjwBacettL~-tcMHf8Dg9ciS0wzaDfYtJqakBW-XJHSPVV5LAn5G3M0NiV0Nx4FADgiD489Rb0FNePOg3vQ7Kls3QnLdPkZhDEywmDSbe3RDQ56WIbi5rNLjqC-9PvqBWQvQ7slgCNDGqEh7tMWvE4014mzCPuq4SfWQU2Yd0uQZ2TcASUwTZUrcmojnDIRJs5Bh~eAFeQl~0cvF~mqwQA2~2tQFRCf8K-JYAKJxjXavWvrV414N6IRie0rcOK4nEg__&Key-Pair-Id=APKAJLY3BVCBIIYTI6EA',
                frameCount: 1930,
                frameRate: 29.97002997002997,
                height: 1650,
                mimeType: 'video/mp4',
                websiteURL: null,
                width: 1275
            };

            var currentTenant = await tenantService.getCurrentTenantObject();
            var s3InfoPayload = {
                id: proofVersion.id,
                proofId: proofVersion.proofId,
                name: proofVersion.name
            };
            let s3MainInfo = await s3Service.getS3MainInfo(s3InfoPayload);
            let evaporateConfig = {
                ...evaporateS3Config,
                bucket: s3MainInfo.bucket,
                awsRegion: s3MainInfo.region,
                aws_key: s3MainInfo.accessKey,
                s3Acceleration : s3MainInfo.transferAcceleration,
                signerUrl: `${config.apiUrl}/s3`,
                signHeaders: { ...authHeader() }
            };
            let documentMetadata = {
                ...fileMetadata,
                proofId: proofVersion.proofId,
                proofVersionId: proofVersion.id,
                currentUser,
                currentTenant,
                s3Config: evaporateConfig,
                documentHeight: fileMetadata.height,
                documentWidth: fileMetadata.width,
                mentionURL: history?.location?.state?.mentionURL
            };
            props.setDocumentMetadata(documentMetadata);

            var commentsPayload = {
                proofId: proofVersion.proofId,
                proofVersionId: proofVersion.id
            };

            props.changeLiveUpdateTarget(proofId, versionId, currentTenant.id);

            var annotationComments = await documentCommentService.getComments(commentsPayload);
            props.setAnnotationComments(annotationComments);
            var contributors = contributorsHelper.configureUserPreference(currentUser, proofVersion, props);
            await contributorsHelper.configureContributors(proofVersion, annotationComments, contributors, currentUser.id, false, props);

            props.setPages(proofVersion.pages);
            props.setPageCount(proofVersion.pages.length);
            // props.loadAndSetTextPages(proofVersion.pages);
            //set selected comment
            // for (let i = 0; i < proofVersion.pages.length; i++) {
            //     var lastIndex = annotationComments.map((e) => e.page).lastIndexOf(i + 1);

            //     if (lastIndex !== -1) {
            //         props.setSelectedComment(lastIndex);
            //         break;
            //     }
            // }

            //configureContributors(currentUser, proofVersion, annotationComments);

        }
        fetchInitData().then(() => {
            console.log('FETCHED');

        });

        props.setDocumentContainer(pagesContainer);
        props.setEditorArea(editorArea);

        
        

        return () => {
            window.onbeforeunload = undefined;
        };
    }, []);
    

    useEffect (()=> {        
        
        var regularNav = isRegularNav();
        if (props.alert.isHideTopbar === true || regularNav !== true) {
            props.setHasExtraTopBar(false);
        }
        else {
            props.setHasExtraTopBar(true);
        }

    }, [props.alert.isHideTopbar]) 

    const shouldShowExitConfirmModal = (nextLocation) => {
        if (utilService.getIsEditorDirty()) {
            setNextLocation(nextLocation);
            setShowExitConfirmModal(true);
            store.dispatch(alertActions.warn('Changes are still saving...' + getIsEditorDirty()));
            store.dispatch(alertActions.loading(true));
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (!props.isProofCompare) { return; }
        props.setCompareIndex(props.selectedCanvas);
    }, [props.selectedCanvas])


    useEffect(() => {
        if (!props.isProofCompare) { return; }

        if (props.changeSelectedCanvas !== undefined) {
            props.changeSelectedCanvas(props.compareIndex, true, -1);
        }
    }, [props.compareIndex])

    useEffect(() => {
        
        changeVisiblePagesDebounced(false);

        if (!props.isProofCompare) { return; }

        if (props.containerZoom !== undefined) {
            props.setCompareContainerZoom(props.containerZoom);
        }

    }, [props.containerZoom])


    useEffect(() => {
        if (!props.isProofCompare) { return; }

        if (props.compareContainerZoom !== undefined) {
            props.setContainerZoom(props.compareContainerZoom);
        }

    }, [props.compareContainerZoom])
    
    useEffect(() => {
        if (!props.isProofCompare) { return; }
        
        if (props.isCompareHandToolSelected !== undefined) {
            props.setIsPanningSelected(props.isCompareHandToolSelected);
        }

    }, [props.isCompareHandToolSelected])

    const onEditorScrolled = (e) => {
        
        const xPos = e.currentTarget.scrollLeft;
        const yPos = e.currentTarget.scrollTop;

        if (xPos !== xScrollPositionRef.current) {
            xScrollPositionRef.current = xPos;
            if (props.isProofCompare) {
                onHorizontalScrolledCompare(e);
            }
            else 
            {
                onHorizontalScrolled(e);
            }
        }
        else if (yPos !== yScrollPositionRef.current) {
            yScrollPositionRef.current = yPos;
            onVerticalScrolled(e);
        }

        props.redrawAnnotationCommentArrow();

        if (props.isProofCompare === true) {
            props.onScrolled(props.side, e, editorArea);
        }
        
        
        // if (props?.selectedComment !== -1) {
        //     props.clearCommentSelection(props.selectedCanvas);
        // }


    }

    const onVerticalScrolled = (e) => {
        if (e.currentTarget.scrollTop == 0 && props.shouldTranslate) {
            var clientRect = props.documentContainer.current.getBoundingClientRect();

            var y = clientRect.y;
            var translationValue = 0;
            if (y < 0) {
                translationValue = 147 + Math.abs(y);
            } else {
                translationValue = 147 - y;
            }
            var scale = convertZoomToScale(props.containerZoom);
            props.documentContainer.current.style.transform = `matrix(${scale}, 0, 0, ${scale}, ${getTranslationX()}, ${translationValue})`;


            props.setShouldTranslate(false);            
        }

        changeVisiblePagesDebounced(true)     
    }

    const changeVisiblePages = (fromScroll)=> {        
               
        var nodes = pagesContainer.current?.children; 
        
        if (nodes === undefined) { return; }
                
        var pages = pagesContainer.current.children;        
        var pagesInView = [];

        for (let i = 0; i < pages.length; i++){
            var elem = pages[i];
            var inView = isScrolledIntoView(elem, editorArea.current, i, props.containerZoom);
            if(inView === true) {
                pagesInView.push({page: i+1, isLoaded : false});
            }
        }

        if(props.isFullyLoadedRef.current === false && fromScroll === true) {
            props.addLoadablePages(pagesInView);
        }

        props.setVisiblePages(pagesInView);
    }

    const changeVisiblePagesDebounced = useCallback(debounce(changeVisiblePages, 100), [props.containerZoom]);

    const getTranslationX = () => {

        //if (props.isProofCompare){ return; }

        var splitted = props.documentContainer.current.style.transform.split(',');
        var x = parseInt(splitted[4]) || 0;
        return x;
    }
    const getTranslationY = () => {

        //if (props.isProofCompare){ return; }
        
        var splitted = props.documentContainer.current.style.transform.split(',');
        var y = parseInt(splitted[5]) || 0;
        return y;
    }

    const onHorizontalScrolled = (e) => {

        if (e.currentTarget.scrollLeft == 0 && props.shouldTranslateHorizontal) {           
            var {shouldTranslate} = isTranslationNeeded();            
            if (shouldTranslate === false ){
                return;
            }

            var clientRect = props.documentContainer.current.getBoundingClientRect();
            var x = clientRect.x;            

            let translationOffset = 150;

            var translationValue = 0;
            if (x < 0) {
                translationValue = translationOffset + Math.abs(x);
            }
            else {
                translationValue = translationOffset - x;
            }
            
            var scale = convertZoomToScale(props.containerZoom);
            props.documentContainer.current.style.transform = `matrix(${scale}, 0, 0, ${scale}, ${translationValue}, ${getTranslationY()})`;
            console.log('the value is :' + translationValue);
            props.setShouldTranslateHorizontal(false);
        }
    }


    const onHorizontalScrolledCompare = (e) => {

        if (e.currentTarget.scrollLeft == 0 && props.shouldTranslateHorizontal) {           
            var {shouldTranslate} = isTranslationNeeded();            
            if (shouldTranslate === false ){
                return;
            }
            console.log(props.side);
            var clientRect = props.documentContainer.current.getBoundingClientRect();
            var x = clientRect.x;            

            let translationOffset = 30 ;

            if (props.side === "right") {
                translationOffset =  translationOffset + props.documentContainer.current.offsetParent.offsetWidth;
            }

            var translationValue = 0;
            if (x < 0) {
                translationValue = translationOffset + Math.abs(x);
            }
            else {
                translationValue = translationOffset - x;
            }
            
            var scale = convertZoomToScale(props.containerZoom);
            props.documentContainer.current.style.transform = `matrix(${scale}, 0, 0, ${scale}, ${translationValue}, ${getTranslationY()})`;
            console.log('the value is :' + translationValue);
            props.setShouldTranslateHorizontal(false);
        }
    }


    const isTranslationNeeded = () => {
        var editorRect = editorArea.current.getBoundingClientRect();
        var pagesRect = pagesContainer.current.getBoundingClientRect();

        let difference = pagesRect.width - editorRect.width;

        return {shouldTranslate : difference > 0,  difference : difference};
    }

    const offsetHorizontalScrollbar = () => {

        //NOTE : As current scroll left is 0, no more left scroll is possible
        //       So scroll left is set to 5 so we can scroll  
        //       When left scroll position is 0 again document will be translated to into 
        //       viewable area. 

        var xTranslation = getTranslationX();

        if (xTranslation != 0) { return; }

        var editorRect = editorArea.current.getBoundingClientRect();
        var pagesRect = pagesContainer.current.getBoundingClientRect();

        
        var {shouldTranslate, difference} = isTranslationNeeded();

        if (shouldTranslate && difference >= 5 && editorArea.current.scrollLeft === 0) {
            editorArea.current.scrollLeft = 5;
        }
    }

    let heightStyle = {};
    let containerStyle = {};
    let editorBodyStyle = {};
    if (alert.isHideTopbar) {
        heightStyle = {
            top: 0,
            height: 'calc(100vh - 22px)'
        };
    }
    if (props.isProofCompare) {
        heightStyle = {
            top: 0,
            height: '100%'
        };
        containerStyle = {
            flexDirection: props.side === 'left'
                ? 'row'
                : 'row-reverse'
        }

        editorBodyStyle = {
            flexDirection: props.side === 'left'
                ? 'row'
                : 'row-reverse'
        }
    }

    if (isViewOnly) {
        heightStyle = {
            top: 0,
            height: '100%'
        };       
    }

    const shouldShowThumbnail = props.isProofCompare 
                                    ? props.showThumbnails 
                                    : (props.proofVersion.mediaType !== PROOF_TYPE.HTMLLive && props.proofVersion.mediaType !== PROOF_TYPE.HTMLDynamicLive);

    const editorContainerClassName = conditionalClassName('mrnda-document-editor-container' , isMobile, 'mobile');
    
    return (
       
        <div style={props.isProofCompare ? { height: '100%' } : isMobile? {height : '100vh'} : {}}
            className="mrnda-document-editor-body">
            {
                // isProofCompare !== true &&
                <AnnotationCommentArrow
                    color={props.arrowColor}                    
                    startPoints = {props.arrowStartPoints}
                    point2={props.arrowPoint2}
                    disable={props.disableArrow}                    
                />
            }

            {
                props.isLoading === true && props.disableLoader !== false &&
                <EditorLoading
                    color={'red'}
                    point1={props.arrowPoint1}
                    point2={props.arrowPoint2}
                    disable={false}
                    isProofCompare={props.isProofCompare}
                    isProofReady={utilService.isProofReady(props.proofVersion)}
                />
            }

            <Prompt
                when={utilService.getIsEditorDirty()}
                message={(nextLocation) => shouldShowExitConfirmModal(nextLocation)}
            />
            <div style={isMobile? {...heightStyle, ...containerStyle, height : '100%'} : {...heightStyle, ...containerStyle}} className={editorContainerClassName}>
                {
                    props.isProofCompare
                        ? props.showComments && (
                            <DocumentAnnotationComment
                                isMobile={isMobile}
                                isProofCompare={props.isProofCompare}
                                side={props.side}
                                compactNavbar = {compactNavbar}
                            />
                        )
                        : !props.hideComments && (
                            <DocumentAnnotationComment
                                annotationComments={ props.annotationComments }
                                isMobile={isMobile}
                                isProofCompare={props.isProofCompare}
                                side={props.side}
                                compactNavbar = {compactNavbar}
                            />
                        )
                }
                <div className={conditionalClassName('mrnda-document-editor-area', isMobile, 'mobile')}>
                    
                    {   editMode && 
                            (isMobile === true 
                                ? <VideoEditorToolboxMobileHOC selectedToolbarAction={props.selectedToolbarAction} setSelectedToolbarAction={props.setSelectedToolbarAction} /> 
                                : <div style={ compactNavbar === true?  {width: `calc(100% + ${props?.userPreferences?.showCommentBar ? 350 : 58}px)`} : {}}> <PageTitleBar compactNavbar = {compactNavbar} style = {{border :'1px solid red'}} /> </div>
                            )
                    }

                    <ProofEditor {...{
                        ...props,
                        shouldShowThumbnail: shouldShowThumbnail,
                        editorArea: editorArea,
                        offsetHorizontalScrollbar: offsetHorizontalScrollbar,
                        onEditorScrolled: onEditorScrolled,
                        pageCount: props.pageCount,
                        pagesContainer: pagesContainer,
                        editorBodyStyle: editorBodyStyle,
                    }} />

                    

                </div>
            </div>
        </div>
    );
};

function PageTitleBar(props) {
    return (
        <>
            {props.compactNavbar === true ? (                
                <WrappedInsidePrimaryNav
                    WrappedComponent={DocumentEditorToolboxHOC}
                    showRightActions={true}
                    style={{ backgroundColor: 'var(--secondary-background-color)' }}
                    primaryActionsStyle={{ marginRight: '0' }}
                    pageActionsStyle={{ paddingLeft: '0px' }}
                    secondaryActionsStyle={{ width: '250px' }}
                    extraText=""
                />
            ) : (
                <DocumentEditorToolboxHOC />
            )}
        </>
    );
}

// ProofDocumentEditorWrapper.contextType = VideoAnnotationContext;
function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const ProofDocumentEditorWrapperHOC = connect(mapStateToProps)(
    withDocumentAnnotationConsumer(ProofDocumentEditorWrapper)
);

const VideoEditorToolboxMobileHOC = withDocumentAnnotationConsumer(VideoEditorToolboxMobile);
const DocumentEditorToolboxHOC = DocumentEditorToolbox; 
export { ProofDocumentEditor, ProofDocumentEditorWrapperHOC as ProofDocumentEditorWrapper };
