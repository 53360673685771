import React from 'react';

const TimelineCollapsedIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '18px',
                width: '18px',
                ...mergedStyle
            }}
            {...restProps}
            >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect width="4.5" height="18" rx="1" fill="currentColor" />
                <rect x="6.75" width="4.5" height="18" rx="1" fill="currentColor" />
                <rect x="13.5" width="4.5" height="18" rx="1" fill="currentColor" />
            </svg>
        </div>
    );
};

export default TimelineCollapsedIcon;
