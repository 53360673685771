import React from 'react';

export const FitToWidthIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '24px',
                width: '24px',
                ...mergedStyle
            }}
            {...restProps}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1310_2048)">
                    <g clipPath="url(#clip1_1310_2048)">
                        {/* <rect width="22" height="22" fill="black" fill-opacity="0.01" /> */}
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.9503 1.27287C11.9323 1.25381 11.9129 1.23604 11.8924 1.21971C11.7814 1.13021 11.6511 1.09366 11.525 1.10039H5.50029C4.28527 1.10039 3.30029 2.08536 3.30029 3.30039V18.7004C3.30029 19.9154 4.28527 20.9004 5.50029 20.9004H16.5003C17.7153 20.9004 18.7003 19.9154 18.7003 18.7004V8.28148C18.7085 8.15155 18.6709 8.01689 18.5768 7.90308C18.5616 7.88448 18.5453 7.86689 18.5278 7.85043L11.9503 1.27287ZM11.0003 2.20039H5.50029C4.89278 2.20039 4.40029 2.69288 4.40029 3.30039V18.7004C4.40029 19.3079 4.89278 19.8004 5.50029 19.8004H16.5003C17.1078 19.8004 17.6003 19.3079 17.6003 18.7004V8.80071H12.6503C11.739 8.80071 11.0003 8.06198 11.0003 7.15071V2.20039ZM16.8226 7.70074L12.1003 2.97734V7.15074C12.1003 7.42075 12.2949 7.64531 12.5514 7.69188L12.6503 7.70074H16.8226Z"
                            fill="currentColor"
                        />
                        <path
                            d="M14.4492 13.2041L11.75 14.7541L11.7496 11.6494L14.4492 13.2041Z"
                            fill="currentColor"
                        />
                        <path
                            d="M7.5498 13.1992L10.249 11.6492L10.2494 14.7539L7.5498 13.1992Z"
                            fill="currentColor"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_1310_2048">
                        <rect width="22" height="22" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_1310_2048">
                        <rect width="22" height="22" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
