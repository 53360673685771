import React, {useState} from "react";
import { drawingConstants } from "@wunderman-thompson/miranda-drawing";
import { documentAnnotationConstants } from "../Context/documentAnnotation.constants";
import cx from 'classnames';
import HandIcon from "../../../_shared/Icon/HandIcon";
import { useKeyboardShortcut, useKeyPressed } from "../../../../_helpers/hooks/useKeyboardShortcut";
import PlayIcon from "../../../_shared/Icon/PlayIcon";
import LinkIcon from "../../../_shared/Icon/LinkIcon";
import { PROOF_TYPE } from "../../proof.constants";
import { utilService } from "../../../../_helpers";
import { FitToWidthIcon } from "../../../_shared/Icon/DesktopToolsIcons/FitToWidthIcon";
import { FitToHeightIcon } from "../../../_shared/Icon/DesktopToolsIcons/FitToHeightIcon";

const EditorToolboxZoom = ({
    setContainerZoom,
    containerZoom,
    onZoomInClicked,
    onFitWidthClicked,
    getItemClassnames,
    onZoomOutClicked,
    onZoomRangeChanged,
    fitToSpace,
    isPanningSelected, 
    setIsPanningSelected,
    isInteractiveSelected, 
    setIsInteractiveSelected,
    editorArea,
    setSelectedTool,
    proofVersion,
    htmlLiveProofViewerInfo
}) => {
    const { ZOOM_STEP, ZOOM_SELECTIONS: zoomSelections } = documentAnnotationConstants;
    const [selectedZoom, setSelectedZoom] = useState(zoomSelections[0]);
    const { ANNOTATION_TYPE } = drawingConstants;
    const { rect, circle, textbox, path, arrow, commentStamp } = ANNOTATION_TYPE;

    useKeyboardShortcut('h', () => { setIsPanningSelected( (prev) =>  !prev) });
    
    const zoomText = (zoom) => {
        if (zoom === undefined) {
            return ' ';
        }
        return `${Math.ceil(zoom)}%`;
    };

    return (
        <div style={{display: 'flex'}}>
                       
            {
                utilService.isWebsiteProof(proofVersion) && 
                    <div
                    style={{ marginTop: 2}}
                    className={cx({
                        'mrnda-video-editor-area-toolbox-shapes--item': true                         
                    })}
                    onClick={()=>{
                        const url = htmlLiveProofViewerInfo.url;
                        // Open a new tab with the specified URL
                        window.open(url, '_blank');
                    }}
                    title={htmlLiveProofViewerInfo.url}>
                    <LinkIcon width={20} height={20}></LinkIcon>
                </div>
            }

            <div
                style={{ marginTop: 2 }}
                className={cx({
                    'mrnda-video-editor-area-toolbox-shapes--item': true,
                     active: isPanningSelected
                })}
                onClick={()=>{
                    setSelectedTool('');
                    setIsPanningSelected(!isPanningSelected)}
                }
                title="Hand Tool    (H)">
                <HandIcon></HandIcon>
            </div>

            <div
                style={{ marginTop: 2 }}
                className={getItemClassnames(commentStamp.typeName)}
                title="Zoom out">
                <i onClick={onZoomOutClicked} className="flaticon-zoom-out"></i>
            </div>

            <div className={getItemClassnames(commentStamp.typeName)} title={"Select Zoom"}>
                <div className="belect zoom-select">
                    <select
                        id="zoomSelectionsSelect"
                        className="mrnda-input-select-document-zoom"
                        name="zoomSelectionsSelect"
                        value={selectedZoom.value}
                        onChange={(event) => {
                            var index = event.nativeEvent.target.selectedIndex;
                            var selectedOption = zoomSelections[index];
                            onZoomRangeChanged(selectedOption);
                        }}>
                        {zoomSelections.map((c) => (
                            <option
                                style={{
                                    display: c.value === -1 ? 'none' : 'block'
                                }}
                                title={c.tooltip}
                                key={c.value}
                                value={c.value}>
                                {c.value === -1 ? zoomText(containerZoom) : c.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div
                style={{ marginTop: 2 }}
                className={getItemClassnames(commentStamp.typeName)}
                title="Zoom In">
                <i onClick={onZoomInClicked} className="flaticon-zoom-in"></i>
            </div>
            <div
                style={{ marginTop: 2 }}
                className={getItemClassnames(commentStamp.typeName)}
                title="Fit to width">
                {/* <i onClick={onFitWidthClicked} className="flaticon-document-width"></i> */}
                <FitToWidthIcon onClick={onFitWidthClicked} />
            </div>
            <div
                style={{ marginTop: 2 }}
                className={getItemClassnames(commentStamp.typeName)}
                title="Fit to height">
                {/* <i
                    onClick={fitToSpace}
                    className="flaticon-document-height-adjustment"></i> */}
                <FitToHeightIcon onClick={fitToSpace} />
            </div>
        
        </div>
    );
};

export { EditorToolboxZoom };
