import React from 'react';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import cx from 'classnames';
const ProofEditorHeader = ({
    proofVersion,
    isNavActionsDisabled,
    shareEnabled,
    onShareClicked,
    onCloseClick,
    hideCloseButton,
    handleCompareClicked
}) => {
    const {isMobile} = useScreenSizes();
    return (
        <div className="mrnda-video-editor-sidebar__header__container">
            <div className="d-flex flex-column" style={ { width:  isMobile?  '100%' : 'calc(100%)' }}>
                <span
                    className = {cx({'mrnda-video-editor-sidebar__header__text' : !isMobile, 'mrnda-video-editor-area-toolbox--mobile__header__title' : isMobile, 'text-truncate' : true})}                    
                    title={proofVersion.name}
                    >
                    {proofVersion.name}
                </span>
                <div className="d-flex align-items-center">
                    <label
                        style={{
                            marginBottom: 0,
                            color: 'var(--secondary-text-color)'
                        }}>{`Version: v${proofVersion.versionNumber}`}                        
                        </label>
                    {
                        (shareEnabled || !isNavActionsDisabled) &&
                        <span style={{ margin: '0 8px 0 8px' }}> | </span>
                    }
                    {isNavActionsDisabled !== true && (
                        <div
                            id="compareDocument"
                            className=""
                            title="Compare Files"
                            style={{ cursor: 'pointer', marginRight: 12 }}
                            onClick={handleCompareClicked}>
                            <i className="far fa-clone"></i>
                        </div>
                    )}
                    {shareEnabled === true && (
                        <div
                            id="shareDocument"
                            className=""
                            title="Share"
                            style={{ cursor: 'pointer', marginRight: 12 }}
                            onClick={onShareClicked}>
                            {/* <i className="far fa-share-nodes"></i> */}
                            <i className="fas fa-share-alt"></i>
                        </div>
                    )}
                    {proofVersion.isLocked && (
                        <>
                            <div id="read-only" title="Read Only" className="d-flex">
                                <i className="fas fa-eye" style={{ fontSize: '20px' }} />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {/* {!hideCloseButton && (
                <div>
                    <button
                        title="Close Annotation"
                        onClick={onCloseClick}
                        className="mrnda-video-editor-sidebar__header_close mrnda-icon-btn"
                        style={{
                            backgroundColor: 'var(--secondary-background-color)',
                            color: 'var(--primary-text-color)',
                            opacity: '1'
                        }}>
                        <i className="fas fa-times fa-2x"></i>
                    </button>
                </div>
            )} */}
        </div>
    );
};

export default ProofEditorHeader;
