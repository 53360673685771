import React from 'react';

export const TextHighlightIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '21px',
                width: '21px',
                ...mergedStyle
            }}
            {...restProps}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="currentColor" />
                <rect x="4" y="4" width="8" height="1" rx="0.5" fill="currentColor" />
                <rect x="6.5" y="12" width="3" height="1" rx="0.5" fill="currentColor" />
                <path
                    d="M8.5 5L8.5 12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5L7.5 5L8.5 5Z"
                    fill="currentColor"
                />
                <path
                    d="M11.5 4C11.7761 4 12 4.22386 12 4.5L12 5.5C12 5.77614 11.7761 6 11.5 6C11.2239 6 11 5.77614 11 5.5L11 4.5C11 4.22386 11.2239 4 11.5 4Z"
                    fill="currentColor"
                />
                <path
                    d="M4.5 4C4.77614 4 5 4.22386 5 4.5L5 5.5C5 5.77614 4.77614 6 4.5 6C4.22386 6 4 5.77614 4 5.5L4 4.5C4 4.22386 4.22386 4 4.5 4Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};
