// @ts-check
import React, { useState, useRef, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { RangeSelector } from '../../..';
import { readableTime, getVideoCurrentTimers, getVideoTimers } from '../VideoAnnotationUtil';
import { withVideoAnnotationConsumer } from '../Context/VideoAnnotationConsumer';
import { videoAnnotationConstants } from '../Context/videoAnnotation.constants';
import { AnnotationMarkers } from './AnnotationMarkers';
import FramePicker from './FramePicker';
import PlayMarkerIcon from '../../../_shared/Icon/PlayMarkerIcon';
import PauseIcon from '../../../_shared/Icon/PauseIcon';
import PlayIcon from '../../../_shared/Icon/PlayIcon';
import BackwardIcon from '../../../_shared/Icon/BackwardIcon';
import ForwardIcon from '../../../_shared/Icon/ForwardIcon';
import ChevronUp from '../../../_shared/Icon/ChevronUp';
import ChevronDown from '../../../_shared/Icon/ChevronDown';
import { useTheme } from '../../../App/Theme/ThemeContext';
import PlayMarker from '../PlayMarker';
import AudioQuietIcon from '../../../_shared/Icon/AudioQuietIcon';
import AudioMutedIcon from '../../../_shared/Icon/AudioMutedIcon';
import AudioLoudIcon from '../../../_shared/Icon/AudioLoudIcon';
import VideoPlayerTimeline from './VideoPlayerTimeline';
import VideoPlayerControlButtons from './VideoPlayerControlButtons';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import VideoPlayerTimelineMobile from './VideoPlayerTimelineMobile';

const initPlayerColors = {
    backgroundColor: '#f7f8f9',
    primaryColor: '#fff',
    primaryTextColor: '#66666a'
};
const minLength = 600;
const VideoPlayerControl = ({
    volume,
    onVolumeChange,
    isPaused,
    onPlayPauseToggle,
    videoMetadata,
    currentTimeInSeconds,
    setCurrentTimeInSeconds,
    rangeSelectorValue,
    setRangeSelectorValue,
    onRangeSliderChange,
    seekToTime,
    timeMode,
    onTimeModeChange,
    onMetadataConfigureShow,
    isCommentAddMode,
    showAnnotationMarkers,
    playerColors,
    framePickerCheckClicked,
    framePickerCloseClicked,
    setCurrentFrameNumber,
    isTranscriptionOnlyMode = false,
    userPreferences,
    handleUpdateUserPreferences
}) => {
    var timersCurrent = getVideoCurrentTimers(currentTimeInSeconds, videoMetadata?.frameRate);
    let timers = getVideoTimers(videoMetadata?.durationInSeconds, videoMetadata?.frameRate);
    const { currentFrameNumber, currentTimeInText, currentTimeInTimeCode } = timersCurrent;
    const { frameCount, durationInText, durationInTimeCode } = timers;

    const { TIME, TIME_FRAME, TIME_CODE } = videoAnnotationConstants.TIME_MODE;
    const progressBar = useRef(null);

    const [displayToolTip, setDisplayToolTip] = useState(false);
    const [toolTipLeftPosition, setToolTipLeftPosition] = useState('0px');
    const [toolTipTimeInText, setToolTipTimeInText] = useState('00:00:00');
    const { theme } = useTheme();
    // const [timeMode, setTimeMode] = useState(videoAnnotationConstants.TIME_MODE.TIME_CODE);
    const { isMobile } = useScreenSizes();

    const onProgressClick = (event) => {
        if (isCommentAddMode) {
            return;
        }
        const frameNumber =
            (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) * frameCount;
        seekToTime(frameNumber);
    };

    const bubblePop = (e) => {
        var cursorPos =
            (e.nativeEvent.clientX - progressBar.current.getBoundingClientRect().left) /
            progressBar.current.offsetWidth;
        var seekTime = cursorPos * videoMetadata?.durationInSeconds;

        if (seekTime) {
            var innerHTML = readableTime(seekTime);
            var styleleft =
                e.nativeEvent.clientX - progressBar.current.getBoundingClientRect().left + 0 + 'px';

            setDisplayToolTip(true);
            setToolTipLeftPosition(styleleft);
            setToolTipTimeInText(innerHTML);
        }
    };

    const onThumbCLick = useCallback(
        (value) => {
            console.log('THUMB_CLICK', value);
            var seekTime = (value / videoMetadata?.frameRate).toFixed(5);
            if (!seekTime) {
                setCurrentTimeInSeconds(seekTime);
                // props.seekToTime(value);
            }
        },
        [rangeSelectorValue]
    );

    const handleOnRangeSliderChange = (valueChange) => {
        var OldRangeSelectorValue = { ...rangeSelectorValue };
        var value = [...valueChange];
        if (value[0] !== OldRangeSelectorValue[0]) {
            var seekTime = (value[0] / videoMetadata?.frameRate).toFixed(5);
            // props.seekToTime(value[0]);
            setRangeSelectorValue([...value]);
            onRangeSliderChange(seekTime, [...value]);
        } else if (value[1] !== OldRangeSelectorValue[1]) {
            var seekTime = (value[1] / videoMetadata?.frameRate).toFixed(5);
            // props.seekToTime(value[1]);
            setRangeSelectorValue([...value]);
            onRangeSliderChange(seekTime, [...value]);
        }
    };

    const getTimers = () => {
        let text;
        switch (timeMode) {
            case TIME_FRAME:
                text = `${currentFrameNumber} / ${frameCount}`;
                break;
            case TIME_CODE:
                text = `${currentTimeInTimeCode} / ${durationInTimeCode}`;
                break;
            case TIME:
            default:
                text = `${currentTimeInText} / ${durationInText}`;
                break;
        }
        return text;
    };

    const getElapsedTime = () => {
        let text;
        switch (timeMode) {
            case TIME_FRAME:
                text = `${currentFrameNumber}`;
                break;
            case TIME_CODE:
                text = `${currentTimeInTimeCode}`;
                break;
            case TIME:
            default:
                text = `${currentTimeInText}`;
                break;
        }
        return text;
    };

    const getTotalTime = () => {
        let text;
        switch (timeMode) {
            case TIME_FRAME:
                text = `${frameCount}`;
                break;
            case TIME_CODE:
                text = `${durationInTimeCode}`;
                break;
            case TIME:
            default:
                text = `${durationInText}`;
                break;
        }
        return text;
    };

    const onStepChange = (value) => {
        if (isCommentAddMode === true) {
            return;
        }

        var newCurrentFrame = currentFrameNumber + value;
        if (value < 0 && newCurrentFrame < 0) {
            return;
        } else if (value > 0 && newCurrentFrame > videoMetadata?.currentFrameNumber) {
            return;
        }
        seekToTime(newCurrentFrame);
    };

    const handleOnTimeModChange = (mode) => {
        onTimeModeChange(mode);
    };

    let volumeIconClass = 'fas fa-volume-up';
    if (volume < 0.5 && volume > 0) {
        volumeIconClass = 'fas fa-volume-down';
    } else if (volume < 0.01) {
        volumeIconClass = 'fas fa-volume-mute';
    } else {
        volumeIconClass = 'fas fa-volume-up';
    }

    var progressBarMargin = !showAnnotationMarkers ? { marginTop: '8px' } : {};
    var timelineHeight = !showAnnotationMarkers ? { height: '62px' } : { height: '62px' };

    let updatedPlayerColors = {
        backgroundColor: playerColors?.backgroundColor
            ? `${playerColors?.backgroundColor}`
            : initPlayerColors.backgroundColor,
        primaryColor: playerColors?.primaryColor
            ? `${playerColors?.primaryColor}`
            : initPlayerColors.primaryColor,
        primaryTextColor: playerColors?.primaryTextColor
            ? `${playerColors?.primaryTextColor}`
            : initPlayerColors.primaryTextColor
    };
    let { primaryColor, backgroundColor, primaryTextColor } = updatedPlayerColors;

    const calculateTimelineLengthForMobile = useCallback(() => {
        var length = frameCount / 2;
        if (length < minLength) {
            length = minLength;
        }
        return `${length}px`;
    }, [frameCount]);

    const areaStyle = isMobile
        ? { margin: 0, padding: 0 }
        : { margin: '0px 16px', padding: 0, borderRadius: 6 };

    return (
        <div
            style={areaStyle}
            className={cx('mrnda-video-editor-area-controls', {
                'mrnda-video-editor-area-controls--mobile': isMobile
            })} /*style={{ background: primaryColor }}*/
        >
            <VideoPlayerControlButtons
                {...{
                    getTimers,
                    volume,
                    onVolumeChange,
                    isPaused,
                    onPlayPauseToggle,
                    timeMode,
                    onMetadataConfigureShow,
                    onStepChange,
                    handleOnTimeModChange,
                    getElapsedTime,
                    getTotalTime,
                    isTranscriptionOnlyMode,
                    userPreferences,
                    handleUpdateUserPreferences,
                }}
            />

            {isMobile === true ? (
                <VideoPlayerTimelineMobile
                    {...{
                        showAnnotationMarkers,
                        currentFrameNumber,
                        frameCount,
                        onProgressClick,
                        handleOnRangeSliderChange,
                        rangeSelectorValue,
                        onThumbCLick,
                        videoMetadata,
                        isCommentAddMode,
                        seekToTime,
                        timeLineWidth: calculateTimelineLengthForMobile()
                    }}
                />
            ) : (
                <VideoPlayerTimeline
                    {...{
                        showAnnotationMarkers,
                        currentFrameNumber,
                        frameCount,
                        onProgressClick,
                        handleOnRangeSliderChange,
                        rangeSelectorValue,
                        onThumbCLick,
                        videoMetadata,
                        isCommentAddMode,
                        seekToTime,
                        userPreferences
                    }}
                />
            )}
        </div>
    );
};

const hoc = withVideoAnnotationConsumer(VideoPlayerControl);
export { hoc as VideoPlayerControl };
