import React from 'react';

export const PinIcon = ({ color = 'currentColor', ...props }) => {
    const mergedStyle = props.style ? { ...props.style } : {};
    const { style: _, ...restProps } = props;
    return (
        <div
            color={color}
            style={{
                height: '21px',
                width: '21px',
                ...mergedStyle
            }}
            {...restProps}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 13 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.03116 13.0085L2.98703 12.0849L6.09814 6.69629L6.96417 7.19629L3.85306 12.5849L3.03116 13.0085Z"
                    fill="currentColor"
                />
                <rect
                    x="7.36597"
                    y="0.5"
                    width="5"
                    height="1"
                    rx="0.5"
                    transform="rotate(30 7.36597 0.5)"
                    fill="currentColor"
                />
                <rect
                    x="3.5"
                    y="5.19629"
                    width="7"
                    height="1"
                    rx="0.5"
                    transform="rotate(30 3.5 5.19629)"
                    fill="currentColor"
                />
                <path
                    d="M7.29898 1.61621L10.7631 3.61621L8.69609 8.19634L4.36597 5.69634L7.29898 1.61621Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};
